/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import Col from '~components/layout/grid/Col';
import FormattedString from '~components/text/FormattedString';
import LinksWidget from '~components/widgets/LinksWidget';

const FooterCustomColumn = ({ columnBreakpoints, headerKey, header, contentKey, content }) => {
    return (
        <Col {...columnBreakpoints}>
            <LinksWidget level={1} title={header && <FormattedString source={headerKey} pattern={header} />} light>
                {content && <FormattedString source={contentKey} pattern={content} />}
            </LinksWidget>
        </Col>
    );
};

FooterCustomColumn.propTypes = {
    columnBreakpoints: PropTypes.object,
    headerKey: PropTypes.string,
    header: PropTypes.string,
    contentKey: PropTypes.string,
    content: PropTypes.string,
};

export default FooterCustomColumn;
