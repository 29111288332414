/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback, useEffect, useRef } from 'react';

const doCleanup = (cleanupRef) => {
    if (cleanupRef.current) {
        const cleanup = cleanupRef.current;
        cleanupRef.current = null;
        cleanup();
    }
};

const useCallbackWithCleanup = (callback, deps) => {
    const cleanupRef = useRef(null);

    useEffect(() => {
        return () => doCleanup(cleanupRef);
    }, []);

    return useCallback((...args) => {
        doCleanup(cleanupRef);
        cleanupRef.current = callback(...args);
    }, /* eslint-disable-line react-hooks/exhaustive-deps */ deps);
};

export default useCallbackWithCleanup;
