/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import loglevel from 'loglevel';

import { addDebugMethod } from './debug';

const DEFAULT_LOG_LEVEL = loglevel.levels.WARN;
loglevel.setDefaultLevel(DEFAULT_LOG_LEVEL);

const loggerNames = new Set();

class Logger {
    /**
     * Creates a new logger
     *
     * @param {symbol} identifier - The identifier Symbol for the logger
     */
    constructor(identifier) {
        const name = identifier.toString().slice(7, -1);
        if (typeof identifier !== 'symbol' || name.length < 3 || loggerNames.has(name)) {
            // Why do we use symbols instead of strings, if we just convert the symbol into a string anyway?
            // Because it makes it easy to detect instances where two loggers inappropriately use the same name
            throw new Error('Loggers should be identified by a uniquely named symbol');
        }
        loggerNames.add(name);

        const logger = loglevel.getLogger(name);
        this._logger = logger;
        this._name = name;
    }

    /**
     * Gets the string name of the logger
     */
    get name() {
        return this._name;
    }

    _log(levelName, logMethod, message, data) {
        const fullMessage = `[${levelName}][${this.name}] ${message}`;
        if (data !== undefined) {
            logMethod(fullMessage, data);
        } else {
            logMethod(fullMessage);
        }
    }

    /**
     * Resets the log to the default log level
     */
    resetLogLevel() {
        this._logger.resetLevel();
    }

    /**
     * Sets the log level to TRACE
     */
    setLevelToTrace() {
        this._logger.setLevel(loglevel.levels.TRACE);
    }

    /**
     * Returns true if TRACE messages will be output when logging
     */
    isTraceEnabled() {
        return this._logger.getLevel() <= loglevel.levels.TRACE;
    }

    /**
     * Writes a log message at a level of TRACE.
     * TRACE logging includes call heirarchy details that can be examined in the browser dev tools.
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    trace(message, data) {
        if (this.isTraceEnabled()) {
            this._log('TRACE', this._logger.trace, message, data);
        }
    }

    /**
     * Sets the log level to DEBUG
     */
    setLevelToDebug() {
        this._logger.setLevel(loglevel.levels.DEBUG);
    }

    /**
     * Returns true if DEBUG messages will be output when logging
     */
    isDebugEnabled() {
        return this._logger.getLevel() <= loglevel.levels.DEBUG;
    }

    /**
     * Writes a log message at a level of DEBUG
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    debug(message, data) {
        if (this.isDebugEnabled()) {
            this._log('DEBUG', this._logger.debug, message, data);
        }
    }

    /**
     * Writes a log message at a level of TRACE or DEBUG, depending on if TRACE logging is enabled or not.
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    traceOrDebug(message, data) {
        if (this.isTraceEnabled()) {
            this.trace(message, data);
        } else {
            this.debug(message, data);
        }
    }

    /**
     * Sets the log level to INFO
     */
    setLevelToInfo() {
        this._logger.setLevel(loglevel.levels.INFO);
    }

    /**
     * Returns true if INFO messages will be output when logging
     */
    isInfoEnabled() {
        return this._logger.getLevel() <= loglevel.levels.INFO;
    }

    /**
     * Writes a log message at a level of INFO
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    info(message, data) {
        if (this.isInfoEnabled()) {
            this._log('INFO ', this._logger.info, message, data);
        }
    }

    /**
     * Sets the log level to WARN
     */
    setLevelToWarn() {
        this._logger.setLevel(loglevel.levels.WARN);
    }

    /**
     * Writes a log message at a level of WARN
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    warn(message, data) {
        this._log('WARN ', this._logger.warn, message, data);
    }

    /**
     * Sets the log level to ERROR
     */
    setLevelToError() {
        this._logger.setLevel(loglevel.levels.ERROR);
    }

    /**
     * Writes a log message at a level of ERROR
     *
     * @param {string} message - The message
     * @param {object} data - Optional data to include in the log output
     */
    error(message, data) {
        this._log('ERROR', this._logger.error, message, data);
    }

    /**
     * Turns off all logging for this logger
     */
    silence() {
        this._logger.setLevel(loglevel.levels.SILENT);
    }
}

/**
 * @type {Map<symbol, Logger>}
 */
const loggers = new Map();

/**
 * Gets the logger for the specified identifier, creating it if it doesn't already exist.
 *
 * @param {symbol} identifier - A Symbol that uniquely identifies the logger.
 * @returns {Logger} The logger for the given identifier
 */
export const getLogger = (identifier) => {
    let logger = loggers.get(identifier);
    if (!logger) {
        logger = new Logger(identifier);
        loggers.set(identifier, logger);
    }
    return logger;
};

/**
 * Gets the logger for the specified name.
 * Note: This should only be used by tests (to assert expected logging) and debugging (DEBUG.LOGGING.getLoggerByName)
 *
 * @param {string} name - The name for the logger.
 * @returns {Logger|null} The logger for the given name (or null, if no such logger exists)
 */
export const getLoggerByName = (name) => {
    for (const logger of loggers.values()) {
        if (logger.name === name) {
            return logger;
        }
    }
    return null;
};

addDebugMethod('LOGGING', 'resetAllLogLevels', () => {
    for (const logger of loggers.values()) {
        logger.resetLogLevel();
    }
});
addDebugMethod('LOGGING', 'getLoggerByName', getLoggerByName);
addDebugMethod('LOGGING', 'getLoggers', () => loggers);
addDebugMethod('LOGGING', 'getLoggerNames', () => loggerNames);
