/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { POSITIVE_INTEGER as validAttributePattern } from './attributePatterns';
import SingleAttributeToken from './singleAttributeToken';

/**
 * Provides vertical padding
 * {vpad=5}Text to be shifted 5px down
 */
export default new SingleAttributeToken(
    'vpad',
    validAttributePattern,
    (key, attributeValue) => {
        return <div key={key} style={{ width: '100%', height: `${attributeValue}px` }} />;
    },
    () => `\n`
);
