/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { getAutomationID } from '~/components/id/AutomationID';
import { buildMobileOrderingID } from '~/components/id/RootAutomationID';
import LocalizedString from '~components/text/LocalizedString';
import useCallbackWithCleanup from '~utils/hooks/useCallbackWithCleanup';

import HeaderToolbarItem from './HeaderToolbarItem';

const HeaderToolbar = ({ mobileMenuShown, setMobileMenuShown, toolbarItems }) => {
    const toolbarRef = useCallbackWithCleanup((toolbar) => {
        if (!toolbar) {
            // No toolbar, so nothing to set up, and nothing to cleanup
            return undefined;
        }
        const keyListener = (e) => {
            let handled = false;
            const activeItem = e.target.parentNode;
            if (activeItem.parentNode === toolbar) {
                const key = e.key;
                if (key === 'ArrowRight') {
                    handled = true;
                    const nextElem = activeItem.nextSibling || activeItem.parentNode.firstChild;
                    const elemToFocus = nextElem.firstChild;
                    elemToFocus.focus();
                } else if (key === 'ArrowLeft') {
                    handled = true;
                    const nextElem = activeItem.previousSibling || activeItem.parentNode.lastChild;
                    const elemToFocus = nextElem.firstChild;
                    elemToFocus.focus();
                }
            }
            if (handled) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        toolbar.addEventListener('keydown', keyListener);
        return () => {
            toolbar.removeEventListener('keydown', keyListener);
        };
    }, []);

    const rootID = buildMobileOrderingID('header');
    return (
        <div ref={toolbarRef} className="toolbar">
            {setMobileMenuShown && (
                <HeaderToolbarItem
                    automationID={getAutomationID(rootID, 'menu')}
                    page={{
                        href: '#',
                        redirect: () => {
                            setMobileMenuShown((s) => !s);
                        },
                    }}
                    icon={mobileMenuShown ? 'x' : 'menu'}
                    label={<LocalizedString localeKey="COMMON_UI.LINK.NAV_MOBILE_MENU" />}
                    visibleOnMobile
                />
            )}
            {toolbarItems.map((toolbarItem, i) => (
                <Fragment key={i}>{toolbarItem}</Fragment>
            ))}
        </div>
    );
};

HeaderToolbar.propTypes = {
    mobileMenuShown: PropTypes.bool,
    setMobileMenuShown: PropTypes.func,
    toolbarItems: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default HeaderToolbar;
