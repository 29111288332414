/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { POSITIVE_INTEGER as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Left indent tags
 * {leftIndent=5}Text to be indented 5px from the left{/b}
 */
export default new SingleAttributeTag(
    'leftIndent',
    validAttributePattern,
    (key, attributeValue, contents) => {
        return (
            <div key={key} style={{ paddingLeft: `${attributeValue}px` }}>
                {contents}
            </div>
        );
    },
    (_attributeValue /* unused in text formatting */, contents) => `\n${contents}\n`
);
