/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { automationIDPropTypes, getExtraAutomationID } from '~/components/id/AutomationID';
import PopupContainer from '~components/a11y/PopupContainer';

const HeaderToolbarPopup = forwardRef(({ automationID, children, className }, ref) => {
    const { id } = getExtraAutomationID(automationID, 'toolbar');
    return (
        <PopupContainer ref={ref} id={id} className={`toolbar-dropdown ${className}`.trim()}>
            {children}
        </PopupContainer>
    );
});
HeaderToolbarPopup.displayName = 'HeaderToolbarPopup';

HeaderToolbarPopup.propTypes = {
    automationID: automationIDPropTypes,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default HeaderToolbarPopup;
