/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

/**
 * Pattern for attributes that allow any value, excluding some special characters that would cause other parsing issues (quotes, curly brackets, and newlines)
 */
export const ANYTHING_BUT_CURLY_AND_QUOTE = /^[^\n{}"]*$/;

/**
 * Pattern matching positive integer values
 */
export const POSITIVE_INTEGER = /^[1-9][0-9]*$/;

/**
 * Pattern matching "boolean" values (Y/N)
 */
export const BOOLEAN = /^[YyNn]$/;

/**
 * Converts an attribute string value into a boolean.
 * Note: Assumes the BOOLEAN pattern was already used for matching the value.
 */
export const parseBoolean = (value) => {
    return value === 'Y' || value === 'y';
};
