/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import ScreenReaderOnlyText from '~components/a11y/ScreenReaderOnlyText';
import getEnhancedComponentName from '~components/getEnhancedComponentName';
import Tooltip, { PlacementPropType } from '~components/tooltip/Tooltip';
import useCallbackRef from '~utils/hooks/useCallbackRef';

/**
 * Wraps an Icon component with an appropriately accessible tooltip.
 *
 * THIS SHOULD NOT BE USED DIRECTLY.
 * See FeatherIcon and MaterialIcon for FeatherIconWithTooltip and MaterialIconWithTooltip exports.
 */
const withIconTooltip = (WrappedComponent) => {
    const EnhancedComponent = ({ tooltipPlacement, tooltip, ...iconProps }) => {
        const [tooltipRef, setTooltipRef] = useCallbackRef();
        return (
            <span ref={setTooltipRef} className="icon-tooltip-container">
                <WrappedComponent {...iconProps} />
                <Tooltip placement={tooltipPlacement} target={tooltipRef}>
                    {tooltip}
                </Tooltip>
                <ScreenReaderOnlyText text={tooltip} />
            </span>
        );
    };

    EnhancedComponent.propTypes = {
        tooltipPlacement: PlacementPropType,
        tooltip: PropTypes.node.isRequired,
    };

    EnhancedComponent.displayName = getEnhancedComponentName('WithIconTooltip', WrappedComponent);

    return EnhancedComponent;
};

export default withIconTooltip;