/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

export const poll = async () => {
    return api.poll('');
};

export const get = async (reviewID) => {
    return api.get(`${reviewID}`);
};

export const approvePayment = async (reviewID) => {
    return api.post(`${reviewID}/approvePayment`);
};

export const returnPayment = async (reviewID, comments) => {
    return api.post(`${reviewID}/returnPayment`, { comments });
};

export const approveProof = async (reviewID) => {
    return api.post(`${reviewID}/approveProof`);
};

export const returnProof = async (reviewID, comments, orderPlacerAttachedFile) => {
    return api.post(`${reviewID}/returnProof`, { comments, orderPlacerAttachedFile }, { isMultiPart: true });
};