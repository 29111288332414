/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import FeatherIcon from '~components/images/icons/FeatherIcon';

import { getAutomationID } from '../id/AutomationID';
import { rootAutomationIDPropTypes } from '../id/RootAutomationID';
import FormattedString from '../text/FormattedString';
import useLocalizationTextFormatted from '../text/useLocalizationTextFormatted';

import MainHelpLink from './help/MainHelpLink';

/**
 * Displays the top header with the email and phone
 *
 * @param {object} props
 * @param {rootAutomationIDPropTypes} props.rootID
 * @param {object} props.printShopHours - A site-level string
 * @param {object} props.email - A site-level email
 * @param {object} props.phone - A site level phone number
 * @returns {React.ReactNode} header
 */
const TopHeader = ({ rootID, printShopHours, email, phone }) => {
    const ariaLabelSiteEmail = useLocalizationTextFormatted('COMMON_UI.ARIA.HEADER__SITE_EMAIL');
    const ariaLabelSitePhone = useLocalizationTextFormatted('COMMON_UI.ARIA.HEADER__SITE_PHONE');

    return (
        <div className="top-header">
            <div className="top-header--left">
                {printShopHours && <FormattedString pattern={printShopHours} />}
            </div>
            <div className="top-header--right">
                {email && (
                    // eslint-disable-next-line jsx-a11y/aria-props
                    <a aria-description={ariaLabelSiteEmail} href={`mailto:${email}`} className="top-header--contact-link" id={getAutomationID(rootID, 'email').id}>
                        <FeatherIcon name="mail" />
                        &nbsp;
                        {email}
                    </a>
                )}
                {phone && (
                    // eslint-disable-next-line jsx-a11y/aria-props
                    <a aria-description={ariaLabelSitePhone} href={`tel:${phone}`} className="top-header--contact-link" id={getAutomationID(rootID, 'phone').id}>
                        <FeatherIcon name="bell" />
                        &nbsp;
                        {phone}
                    </a>
                )}
                <MainHelpLink />
            </div>
        </div>
    );
};

TopHeader.propTypes = {
    rootID: rootAutomationIDPropTypes,
    printShopHours: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
};

export default TopHeader;
