/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useState } from 'react';

import useEffectDeepEqualDeps from '~utils/hooks/useEffectDeepEqualDeps';
import { getLogger } from '~utils/logging';

import { renderAsText } from './rsaMessageFormat';
import LocalizedParameter from './rsaMessageFormat/LocalizedParameter';
import useLocalizations from './useLocalizations';

const logger = getLogger(Symbol('Components:Text:useLocalizationTextFormatted'));

const addAllLocalizedParametersArray = (keysToLocalize, parameters) => {
    for (const parameter of parameters) {
        if (parameter instanceof LocalizedParameter) {
            keysToLocalize.push(parameter.localeKey);
            addAllLocalizedParameters(keysToLocalize, parameter.parameters);
        }
    }
};

const addAllLocalizedParametersObject = (keysToLocalize, parameters) => {
    for (const parameter of Object.values(parameters)) {
        if (parameter instanceof LocalizedParameter) {
            keysToLocalize.push(parameter.localeKey);
            addAllLocalizedParameters(keysToLocalize, parameter.parameters);
        }
    }
};

const addAllLocalizedParameters = (keysToLocalize, parameters) => {
    if (parameters) {
        if (Array.isArray(parameters)) {
            addAllLocalizedParametersArray(keysToLocalize, parameters);
        } else {
            addAllLocalizedParametersObject(keysToLocalize, parameters);
        }
    }
};

const getLocalizedParametersArray = (localizations, parameters) => {
    const localizedParameters = [];
    for (const parameter of parameters) {
        if (parameter instanceof LocalizedParameter) {
            localizedParameters.push(renderStep(localizations, parameter.parameters));
        } else {
            localizedParameters.push(parameter);
        }
    }
    return localizedParameters;
};

const getLocalizedParametersObject = (localizations, parameters) => {
    const localizedParameters = {};
    for (const [key, value] of Object.entries(parameters)) {
        if (value instanceof LocalizedParameter) {
            localizedParameters[key] = renderStep(localizations, value.parameters);
        } else {
            localizedParameters[key] = value;
        }
    }
    return localizedParameters;
};

const renderStep = (localizations, parameters) => {
    const localization = localizations.shift();
    let localizedParameters;
    if (parameters) {
        if (Array.isArray(parameters)) {
            localizedParameters = getLocalizedParametersArray(localizations, parameters);
        } else {
            localizedParameters = getLocalizedParametersObject(localizations, parameters);
        }
    }
    return localization ? renderAsText(localization, localizedParameters) : undefined;
};

const render = (setLocalization, localizations, parameters) => {
    if (logger.isDebugEnabled()) {
        logger.debug(`Rendering ${JSON.stringify({ localizations, parameters })}`);
    }
    // We clone the localizations array because we modify it in renderStep, and don't want to change the version the caller has
    setLocalization(renderStep([...localizations], parameters));
};

const useLocalizationTextFormatted = (localeKey, parameters) => {
    const [localization, setLocalization] = useState('');
    const keysToLocalize = localeKey ? [localeKey] : [];
    addAllLocalizedParameters(keysToLocalize, parameters);
    const localizations = useLocalizations(keysToLocalize);
    useEffectDeepEqualDeps(() => {
        if (logger.isDebugEnabled()) {
            logger.debug(
                `useLocalizationTextFormatted's useEffect called ${JSON.stringify({ localizations, parameters })}`
            );
        }
        if (localizations.length > 0 && localizations.every((value) => typeof value === 'string')) {
            render(setLocalization, localizations, parameters);
        }
    }, [setLocalization, localizations, parameters]);
    return localization;
};

export default useLocalizationTextFormatted;
