/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { isValidList, renderListItems, renderListItemsAsText } from './listItem';
import * as rawTagRenderer from './rawTagRenderer';
import Tag from './tag';

/**
 * Ordered (numbered) lists
 * {ol}{li}First list item{/li}{li}Second list item{/li}...{/ol}
 */
class OrderedList extends Tag {
    constructor() {
        super('ol');
    }

    renderTag({ parsedTag, keyGen, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidList(contents)) {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        return <ol key={keyGen.next}>{renderListItems({ contents, keyGen, renderContents })}</ol>;
    }

    renderTagAsText({ parsedTag, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidList(contents)) {
            return rawTagRenderer.renderAsText(parsedTag, renderContents);
        }
        return `\n${renderListItemsAsText({ contents, renderContents })}\n`;
    }
}

export default new OrderedList();
