/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * H3 tags
 * {h3}Text to be heading level 3{/h3}
 */
export default new SimpleTag(
    'h3',
    (key, contents) => {
        return <h3 key={key}>{contents}</h3>;
    },
    (contents) => contents
);
