/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

const path = 'privacyPolicy';

export const get = async () => {
    return api.get(path);
};

export const post = async () => {
    return api.post(path);
};
