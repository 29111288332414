/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Col as BCol } from 'reactstrap';

const Col = ({ children, className, xs, sm, md, lg, xl }) => {
    return (
        <BCol className={className} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            {children}
        </BCol>
    );
};

const COLUMN_SIZE_PROP_TYPE = PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto']);

const COLUMN_PROP_TYPE = PropTypes.oneOfType([
    PropTypes.shape({
        size: COLUMN_SIZE_PROP_TYPE,
        order: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    }),
    COLUMN_SIZE_PROP_TYPE,
]);

Col.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    xs: COLUMN_PROP_TYPE,
    sm: COLUMN_PROP_TYPE,
    md: COLUMN_PROP_TYPE,
    lg: COLUMN_PROP_TYPE,
    xl: COLUMN_PROP_TYPE,
};

export default Col;
