/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { memo } from 'react';

import mainContentID from '~components/a11y/mainContentID';

const UntitledPage = ({ children }) => {
    return <main id={mainContentID}>{children}</main>;
};

UntitledPage.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(UntitledPage);
