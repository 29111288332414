/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import LocalizedString from '../text/LocalizedString';
import { textFormattedParametersPropType } from '../text/rsaMessageFormat/propTypes';
import useLocalizationTextFormatted from '../text/useLocalizationTextFormatted';

import SmartImageStaticAlt, { showTooltip } from './SmartImageStaticAlt';

const SmartImage = ({ localeKey, parameters, tooltipPlacement, ...props }) => {
    const alt = useLocalizationTextFormatted(localeKey, parameters);
    const tooltip = showTooltip(tooltipPlacement) ? <LocalizedString localeKey={localeKey} parameters={parameters} /> : undefined;
    return <SmartImageStaticAlt alt={alt} tooltip={tooltip} tooltipPlacement={tooltipPlacement} {...props} />;
};

SmartImage.propTypes = {
    localeKey: PropTypes.string.isRequired,
    parameters: textFormattedParametersPropType,
    tooltipPlacement: PropTypes.any,
};

export default SmartImage;
