/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ARIALiveContext = createContext(null);

/**
 * Anything that is displayed in this div is read by the screen-reader, regardless of focus.
 */
const ARIALiveProvider = ({ children }) => {
    const [message, setMessage] = useState('');

    const addLiveMessage = useCallback(
        (message) => {
            setMessage(message);
        },
        [setMessage]
    );

    const value = useMemo(
        () => ({
            addLiveMessage,
        }),
        [addLiveMessage]
    );

    return (
        <ARIALiveContext.Provider value={value}>
            {children}
            {message && (
                <div id="live-messages" aria-live="assertive">
                    {message}
                </div>
            )}
        </ARIALiveContext.Provider>
    );
};

ARIALiveProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

const useAddLiveMessage = () => {
    const { addLiveMessage } = useContext(ARIALiveContext);
    return addLiveMessage;
};

export { ARIALiveProvider, useAddLiveMessage };
