/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { POSITIVE_INTEGER as validAttributePattern } from './attributePatterns';
import SingleAttributeToken from './singleAttributeToken';

/**
 * Provides horizontal padding
 * {hpad=5}Text to be shifted 5px to the right
 */
export default new SingleAttributeToken(
    'hpad',
    validAttributePattern,
    (key, attributeValue) => {
        return <span key={key} style={{ width: `${attributeValue}px`, height: '1px', display: 'inline-block' }} />;
    },
    () => ' '
);
