/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import Button from '~components/buttons/Button';

import HelpKeyDebug from './HelpKeyDebug';
import useShowHelpCallback from './useShowHelpCallback';

const HelpLink = ({ className, webHelpKey, children }) => {
    const showHelp = useShowHelpCallback(webHelpKey);
    return (
        <Button inline type="link" action={showHelp} className={className}>
            {children}
            <HelpKeyDebug webHelpKey={webHelpKey} />
        </Button>
    );
};

HelpLink.propTypes = {
    className: PropTypes.string,
    webHelpKey: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default HelpLink;
