/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useCallback, useEffect, useState } from 'react';

import useLocalizationTextFormatted from '~components/text/useLocalizationTextFormatted';

import FeatherIcon from '../images/icons/FeatherIcon';

const scrollToTop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const MIN_OFFSET = 150;

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const altText = useLocalizationTextFormatted('COMMON_UI.IMGALT.SCROLL_TO_TOP');

    // Show button when page is scrolled upto given distance
    const toggleVisibility = useCallback(() => {
        setIsVisible(window.pageYOffset >= MIN_OFFSET);
    }, [setIsVisible]);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, [toggleVisibility]);

    let className = 'scroll-to-top-btn';
    if (isVisible) {
        className += ' visible';
    }

    return (
        <a className={className} href="#" onClick={scrollToTop} aria-label={altText}>
            <FeatherIcon name="chevron-up" />
        </a>
    );
};

export default ScrollToTop;
