/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

const ScreenReaderOnlyText = ({ text }) => {
    return <span className="visually-hidden">{text}</span>;
};

ScreenReaderOnlyText.propTypes = {
    text: PropTypes.node.isRequired,
};

export default ScreenReaderOnlyText;
