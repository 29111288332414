/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

const portalRoot = document.createElement('div');
portalRoot.setAttribute('data-portal-root', '');
document.body.appendChild(portalRoot);

/**
 * Creates a Portal that moves the child React Components out of the DOM hierarchy of the parent components.
 * This is useful when a component needs to exist and be styled independently from the parent DOM node, but is logically
 * a child of the parent React Component.
 * Example: A React component that includes a link that opens a Modal Window will want to contain both the link and the modal
 *          from a logical standpoint, but the modal DOM nodes should exist separately to the link so that it can be more easily
 *          styled and positioned independently from the link.
 *
 * See also: https://reactjs.org/docs/portals.html
 */
const createLabeledPortal = (name) => {
    const Portal = ({ children }) => {
        const [portalElement] = useState(() => {
            const element = document.createElement('div');
            element.setAttribute('data-portal', name);
            return element;
        });
        useEffect(() => {
            portalRoot.appendChild(portalElement);
            return () => {
                portalRoot.removeChild(portalElement);
            };
        }, [portalElement]);

        return createPortal(
            <>
                {children}
            </>,
            portalElement
        );
    };

    Portal.displayName = `Portal-${name}`;

    Portal.propTypes = {
        children: PropTypes.node.isRequired,
    };

    return Portal;
};

export default createLabeledPortal;
export { portalRoot };
