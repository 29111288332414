/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { AlertType } from './AlertType';

const typeToColor = new Map();
typeToColor.set('muted', 'muted');
typeToColor.set(AlertType.INFORMATION, 'info');
typeToColor.set(AlertType.SUCCESS, 'success');
typeToColor.set(AlertType.USER_ERROR, 'warning');
typeToColor.set(AlertType.SYSTEM_ERROR, 'danger');

const Toast = ({ type, onDismiss, children, fading }) => {
    const color = typeToColor.get(type);
    const classes = ['rsa-toast', `rsa-toast-${color}`];
    if (fading) {
        classes.push('rsa-toast-fading');
    }
    return (
        // The alert role will force the screen reader to announce the text of the toast even if it is not in focus
        <div role="alert" className="rsa-toast-container">
            <div className={classes.join(' ')}>
                <div className="rsa-toast-body">{children}</div>
                <button className="rsa-toast-close" onClick={onDismiss} />
            </div>
        </div>
    );
};

Toast.propTypes = {
    type: PropTypes.oneOf([AlertType.INFORMATION, AlertType.SUCCESS, AlertType.USER_ERROR, AlertType.SYSTEM_ERROR]).isRequired,
    onDismiss: PropTypes.func,
    children: PropTypes.node.isRequired,
    fading: PropTypes.bool,
};

export default Toast;
