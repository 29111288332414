/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk } from '@reduxjs/toolkit';

import deepCopy from '~/utils/objects/deepCopy';
import { getLogger } from '~utils/logging';

const logger = getLogger(Symbol('CommonStore:Helpers:SpamProtection'));

/**
 * Flags an execution as being in progress
 */
const IN_PROGRESS = Object.freeze({});

/**
 * Flags an execution as being in progress, an an update request has come in after the last request started
 */
const IN_PROGRESS_AND_QUEUED = Object.freeze({});

// We have a timer in spamProtection that will fire off the changes to the server in 350 ms. If another
// updateOrder request is sent before that timer is up, we clear the timer and set a new one for 350 ms,
// this keeps happening until no more updateOrder() are called before the 350 ms deadline, at which that time the
// timer expires and all the changes queued up are merged together and sent to the server
const CONSOLIDATED_UPDATE_DELAY = 350;

/**
 * Wraps an async thunk in a debounce-like wrapper, ensuring that it will execute at most once every `delay` milliseconds
 *
 * @example ```js
 * const SOMETHING_REFRESH_DELAY = 1000;
 *
 * export const getSomething = createSpamProtectedAsyncThunk(
 *     'something/get',
 *     async ({ id }, { rejectWithValue }) => {
 *         try {
 *             return await fetchSomething(id);
 *         } catch (error) {
 *             return rejectWithValue(error.message);
 *         }
 *     }, {
 *         delay: SOMETHING_REFRESH_DELAY,
 *     }
 * );
 * ```
 *
 * If `getSomething()` is dispatched 5 times in the same second, it will only send the fetchSomething request only twice (effectively
 * the first and second requests [technically the first is sent twice, but the first and second should be effectively identical]).
 * Note: `id` MUST be effectively unique for any given set of arguments, otherwise two different requests could get merged.
 * The first argument to getSomething can also optionally include a callback, which will be executed after the dispatch completes.
 * Additional arguments can be passed along in the first argument, if necessary (make sure id is the same for all variations of arguments)
 */
export const createSpamProtectedAsyncThunk = (typePrefix, payloadCreator, options) => {
    const lastExecutionFinished = new Map();
    let callbacks = [];
    let queuedCallbacks = [];
    const { delay, condition, ...otherOptions } = options;

    const wrappedCondition = (arg, thunkAPI) => {
        if (condition && condition(arg, thunkAPI) === false) {
            return false;
        }

        const { id, callback } = arg;
        const lastFetched = lastExecutionFinished.get(id);
        if (lastFetched === IN_PROGRESS_AND_QUEUED) {
            callback && queuedCallbacks.push(callback);
            return false;
        }
        if (lastFetched === IN_PROGRESS) {
            lastExecutionFinished.set(id, IN_PROGRESS_AND_QUEUED);
            callback && queuedCallbacks.push(callback);
            return false;
        }
        callback && callbacks.push(callback);

        return true;
    };

    const wrappedPayloadCreator = async (arg, thunkAPI) => {
        const { id } = arg;
        const lastFetched = lastExecutionFinished.get(id);
        const timeSinceLastFetch = Date.now() - (lastFetched || 0);
        const fetchDelay = Math.max(10, delay - timeSinceLastFetch);
        lastExecutionFinished.set(id, IN_PROGRESS);

        return new Promise((resolve) => {
            setTimeout(async () => {
                resolve(await payloadCreator(arg, thunkAPI));

                for (const cb of callbacks) {
                    // Delay the callback slightly, so any dispatch done by `action` can finish first
                    setTimeout(cb, 0);
                }
                callbacks = queuedCallbacks;
                queuedCallbacks = [];
                const fetchAgain = lastExecutionFinished.get(id) === IN_PROGRESS_AND_QUEUED;
                lastExecutionFinished.set(id, Date.now());
                if (fetchAgain) {
                    thunkAPI.dispatch(thunk(arg));
                }
            }, fetchDelay);
        });
    };
    const thunk = createAsyncThunk(typePrefix, wrappedPayloadCreator, {
        condition: wrappedCondition,
        ...otherOptions,
    });
    return thunk;
};

/**
 * @typedef {number|string} ConsolidatedUpdateID
 */

/**
 * @typedef ConsolidatedUpdateArgs
 * @property {ConsolidatedUpdateID} id The ID for the object to be updated
 * @property {object} changes The changes to be applied to the object
 * @property {function():void} callback An optional callback to call after the changes are sent to the server
 */

/**
 * @typedef UpdateCallOptions
 * @property {ConsolidatedUpdateID} id The ID for the object to be updated
 * @property {object} changes The changes to be applied to the object
 */

/**
 * @typedef OnUpdateOptions
 * @property {ConsolidatedUpdateID} id The ID for the object to be updated
 * @property {object} response The response received from the updateCall
 * @property {object} appliedChanges The changes that were applied by the update call
 * @property {object} pendingChanges Changes that have been queued up, but not yet sent to the server
 * @property {function(object):void} resolve Function to call to resolve the update. On success, the provided object is passed to the fulfilled
 *                                           reducer's payload. Use thunkAPI.rejectWithValue on failures to pass the value to the rejected reducer.
 * @property {object} thunkAPI Access to the Thunk API.
 */

/**
 * Used when you want to update the server but there is a potential for many updates to come at once so you want to
 * batch them together until no more updates come in and then send that batch all at once. This prevents spamming on the server.
 * The {delay} is the amount of time between updates that is allowed before it will send a batch of changes to the server.
 * So if the delay is 350 ms. Update 1 is queued, 200 ms pass, update 2 is queued, 50 ms pass, update 3 is queued, 400 ms pass, update 4 is queued,
 * updates 1-3 are batched together and sent off to the server, update 4 is sent alone
 *
 * @param {string} typePrefix - A prefix used for logging
 * @param {object} options
 * @param {function(UpdateCallOptions):Promise<object>} options.updateCall - The function to call to send the API request to update the server with changes.
 * @param {function(OnUpdateOptions):void} options.onUpdate - Function called after updateCall finishes successfully.
 * @param {function(ConsolidatedUpdateArgs, object):void} options.onQueued - Function called as soon as new changes are queued. The first argument is the
 *                                                                           arguments passed to the AsyncThunk from the caller, and the second argument is
 *                                                                           the Thunk API object
 * @param {function(object, object):void} options.merge - Function to merge pending changes together (the `merge` function in `mergeUpdates.js` is often sufficient)
 * @returns {Function} a abstracted function that completely handles queuing, batching, sending updates, and responses
 */
export const createConsolidatedUpdateAsyncThunk = (typePrefix, { updateCall, onUpdate, onQueued, merge }) => {
    const changeQueue = new Map();

    const getPendingChanges = (id) => {
        const newChanges = changeQueue.get(id);
        const merged = deepCopy(newChanges?.sending || {});
        const queued = newChanges?.queued || {};
        merge(merged, queued);
        return merged;
    };

    const sendQueuedChanges = async (id, resolve) => {
        const queuedChanges = changeQueue.get(id);
        if (!queuedChanges) {
            // There weren't ever any queued changes... this shouldn't ever happen
            logger.error(`sendQueuedChanges called for [${id}], but no changes were ever queued`);
            return;
        }
        if (!resolve) {
            // We weren't passed a resolve function, this is expected behavior with a race contition like:
            // First set of changes queued
            // First queue timeout reached, sendQueuedChanges called
            // Second set of changes queued
            // First sendQueuedChanges invocation reaches the end, recurses with nextSendResolve function, which was never set
            // Recursive call of sendQueuedChanges picks up the new set of changes
            // Second queue timeout is reached sometime after that
            // Here we are, with a valid set of changes, but no resolve function
            // i.e. if the first set of changes finishes sending between the time that the second set of changes is queued, and the timeout finishes to call this method
            logger.info(`sendQueuedChanges called for [${id}], but no resolve function was given`);
            return;
        }

        if (queuedChanges.sending) {
            // We're already in the process of sending changes to the server - stop to minimize the risk of a race condition
            // where a previous update overwrites a new update because the new update got processed faster
            queuedChanges.nextSendResolve = resolve;
            return;
        }

        if (!queuedChanges.queued) {
            // We had queued changes before, but they've all finished being processed. Clean up after ourselves.
            changeQueue.delete(id);
            return;
        }

        const changesToSend = queuedChanges.queued;
        queuedChanges.queued = null;
        queuedChanges.sending = changesToSend;
        const callbacks = queuedChanges.queuedCallbacks;
        queuedChanges.queuedCallbacks = [];

        try {
            const response = await updateCall({ id, changes: changesToSend });
            const newChanges = changeQueue.get(id);
            onUpdate({
                id,
                response,
                appliedChanges: changesToSend,
                pendingChanges: newChanges.queued,
                resolve,
                thunkAPI: queuedChanges.thunkAPI,
            });
        } catch (error) {
            resolve(queuedChanges.thunkAPI.rejectWithValue({ error: error.message }));
        } finally {
            for (const callback of callbacks) {
                callback();
            }

            // We're done with this batch, call sendQueuedChanges again because we might have new data now
            queuedChanges.sending = null;
            const nextSendResolve = queuedChanges.nextSendResolve;
            queuedChanges.nextSendResolve = null;
            sendQueuedChanges(id, nextSendResolve);
        }
    };

    // createAsyncThunk is REDUX code. You give it an async function to run and the promise returned is used by redux
    // The 'resolve' function is provided by base Javascript libraries to the executor specified in a Promise
    // It's a way for the executor to specify to Javascript that the Promise has finished and is settled
    // After the resolve or reject functions are called, the Promise is settled, and then the redux 'fulfilled' method will be called
    const thunk = createAsyncThunk(typePrefix, async (arg, thunkAPI) => {
        onQueued(arg, thunkAPI);

        const { id, changes, callback } = arg;

        let queuedChanges = changeQueue.get(id);
        if (!queuedChanges) {
            queuedChanges = {
                queuedCallbacks: [],
            };
            changeQueue.set(id, queuedChanges);
        }
        if (!queuedChanges.queued) {
            queuedChanges.queued = {};
        }
        merge(queuedChanges.queued, changes);
        if (callback) {
            queuedChanges.queuedCallbacks.push(callback);
        }

        queuedChanges.thunkAPI = thunkAPI;

        // Clear out the previously queued send (if there was one)
        // We "reject" the old one so redux-thunk knows we've abandonded it and doesn't hold onto it in memory
        clearTimeout(queuedChanges.queueTimeout);
        queuedChanges.reject && queuedChanges.reject();

        // Queue up sending the changes to the server
        return new Promise((resolve, reject) => {
            queuedChanges.reject = reject;
            queuedChanges.queueTimeout = setTimeout(() => {
                queuedChanges.queueTimeout = null;
                queuedChanges.reject = null;
                sendQueuedChanges(id, resolve);
            }, CONSOLIDATED_UPDATE_DELAY);
        });
    });
    return {
        thunk,
        getPendingChanges,
    };
};
