/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { lookupComponent } from './icons/VALID_LIBRARIES';

/**
 * Creates an Icon with a specifiable library.
 *
 * Note: If the library is known at development time, use FeatherIcon or MaterialIcon directly instead of this component.
 * This component should only be used if library changes based on system configuration.
 */
const Icon = ({ library, ...iconProps }) => {
    const IconType = lookupComponent(library);
    return <IconType {...iconProps} />;
};

Icon.propTypes = {
    library: PropTypes.string,
};

export default Icon;
