/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Tooltip as BTooltip } from 'reactstrap';

import useUniqueID from '~utils/hooks/useUniqueID';

export const PlacementPropType = PropTypes.oneOf(['auto', 'left', 'right', 'top', 'bottom']);

const HOVER_IN_DELAY = 350;
const HOVER_OUT_DELAY = 50;

const useOpenCloseToolip = ({ target, tooltipID }) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        if (!target) {
            return;
        }
        target.setAttribute('aria-labelledby', tooltipID);
        setIsOpen(true);
    }, [target, setIsOpen, tooltipID]);

    const close = useCallback(() => {
        if (!target) {
            return;
        }
        target.removeAttribute('aria-labelledby');
        setIsOpen(false);
    }, [target, setIsOpen]);

    return [isOpen, open, close];
};

const useOnHover = ({ open, close }) => {
    const hoverTimeoutRef = useRef(null);
    const clearHoverTimeout = useCallback(() => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
            hoverTimeoutRef.current = null;
        }
    }, [hoverTimeoutRef]);

    const onHoverIn = useCallback(() => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }
        hoverTimeoutRef.current = setTimeout(open, HOVER_IN_DELAY);
    }, [hoverTimeoutRef, open]);

    const onHoverOut = useCallback(() => {
        if (hoverTimeoutRef.current) {
            clearTimeout(hoverTimeoutRef.current);
        }
        hoverTimeoutRef.current = setTimeout(close, HOVER_OUT_DELAY);
    }, [hoverTimeoutRef, close]);

    return [onHoverIn, onHoverOut, clearHoverTimeout];
};

const useOnFocus = ({ target, clearHoverTimeout, open, close }) => {
    const onFocusIn = useCallback(() => {
        clearHoverTimeout();
        if (target) {
            let focusVisible;
            try {
                focusVisible = target.matches(':focus-visible');
            } catch (e) {
                // Browser doesn't support the selector - assume focus is visible;
                focusVisible = true;
            }
            if (focusVisible) {
                open();
            }
        }
    }, [clearHoverTimeout, target, open]);

    const onFocusOut = useCallback(() => {
        clearHoverTimeout();
        close();
    }, [clearHoverTimeout, close]);

    return [onFocusIn, onFocusOut];
};

/**
 * Use `useCallbackRef` to define the target, not `useRef` - `useRef` doesn't provide any kind of notification when the ref
 * gets set, so if the component doesn't need to render again after the first time, the tooltip will only ever render without
 * a target, and therefore will never actually be visible.
 *
 * @example ```js
 * import Tooltip from '~components/tooltip/Tooltip';
 * import useCallbackRef from '~utils/hooks/useCallbackRef';
 *
 * const ElementWithTooltip = () => {
 *     const [tooltipRef, setTooltipRef] = useCallbackRef();
 *     return (
 *         <span ref={setTooltipRef}>
 *             This is always visible
 *             <Tooltip placement="right" target={tooltipRef}>
 *                 This is what is shown in the tooltip
 *             </Tooltip>
 *         </span>
 *     );
 * };
 * ```
 */
const Tooltip = ({ placement, children, target }) => {
    const tooltipID = useUniqueID('tooltip');
    const [isOpen, open, close] = useOpenCloseToolip({ target, tooltipID });
    const [onHoverIn, onHoverOut, clearHoverTimeout] = useOnHover({ open, close });
    const [onFocusIn, onFocusOut] = useOnFocus({ target, clearHoverTimeout, open, close });

    useEffect(() => {
        if (!target) {
            return undefined;
        }
        target.addEventListener('focusin', onFocusIn);
        target.addEventListener('focusout', onFocusOut);
        target.addEventListener('mouseover', onHoverIn);
        target.addEventListener('mouseout', onHoverOut);
        return () => {
            target.removeEventListener('focusin', onFocusIn);
            target.removeEventListener('focusout', onFocusOut);
            target.removeEventListener('mouseover', onHoverIn);
            target.removeEventListener('mouseout', onHoverOut);
        };
    }, [target, onFocusIn, onFocusOut, onHoverIn, onHoverOut]);

    if (!target || !isOpen) {
        return null;
    }
    return (
        <BTooltip
            id={tooltipID}
            target={target}
            placement={placement}
            fade={false}
            isOpen
            arrowClassName="tooltip-arrow"
        >
            {children}
        </BTooltip>
    );
};

Tooltip.propTypes = {
    placement: PlacementPropType,
    children: PropTypes.node.isRequired,
    target: PropTypes.object,
};

Tooltip.defaultProps = {
    placement: 'auto',
};

export default Tooltip;
