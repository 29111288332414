/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import UntitledPage from '~components/page/UntitledPage';
import Heading from '~components/text/Heading';
import LocalizedString from '~components/text/LocalizedString';
import useLocalizations from '~components/text/useLocalizations';

const TITLE_LOCALE_KEY = 'COMMON_UI.TITLE.MAJOR_ERROR_PAGE';
const BODY_LOCALE_KEY = 'COMMON_UI.MESSAGE.MAJOR_ERROR_PAGE';

export const useLocalizationPrefetch = () => {
    useLocalizations([TITLE_LOCALE_KEY, BODY_LOCALE_KEY]);
};

const MajorErrorPage = () => {
    // TODO JAS webcrd_dev-34 Determine what behavior we want if an error happens high-enough up the call chain that we don't have routing
    return (
        <UntitledPage>
            <div className="container container5xx">
                <Heading level={1}>
                    <LocalizedString localeKey={TITLE_LOCALE_KEY} />
                </Heading>
                <p>
                    <LocalizedString localeKey={BODY_LOCALE_KEY} />
                </p>
            </div>
        </UntitledPage>
    );
};

export default MajorErrorPage;
