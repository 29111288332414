/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import FeatherIcon from '~components/images/icons/FeatherIcon';
import localizedStringOrNode, { localizedStringOrNodePropTypes } from '~components/text/LocalizedStringOrNode';

import { buildMobileOrderingID } from '../id/RootAutomationID';

import HeaderSearch from './HeaderSearch';

const MobileMenuLink = ({ page }) => {
    const className = page.isCurrent || page.isChildCurrent ? 'active' : '';
    const role = page.href === '#' ? 'button' : undefined;
    return (
        <li className={className}>
            <a href={page.href} role={role} onClick={page.redirect}>
                {page.icon && (<>
                    <FeatherIcon name={page.icon}/>{' '}
                </>)}
                {localizedStringOrNode(page.label)}
            </a>
        </li>
    );
};

MobileMenuLink.propTypes = {
    page: PropTypes.shape({
        redirect: PropTypes.func.isRequired,
        isCurrent: PropTypes.bool,
        isChildCurrent: PropTypes.bool,
        icon: PropTypes.string,
        href: PropTypes.string.isRequired,
        label: localizedStringOrNodePropTypes,
    }),
};

const MobileMenu = ({ shown, searchOptions, toolbar, pages }) => {
    const rootID = buildMobileOrderingID('header');
    const classes = ['mobile-menu'];
    if (shown) {
        classes.push('open');
    }
    return (
        <div className={classes.join(' ')}>
            {searchOptions &&
            <HeaderSearch
                rootID={rootID}
                {...searchOptions} />}
            {toolbar && <div className="toolbar">{toolbar.toolbarItems}</div>}
            {pages && (
                <div className="slideable-menu">
                    <ul className="menu">
                        {pages.map((page, i) => {
                            if (page) {
                                return <MobileMenuLink key={i} page={page} />;
                            }
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

MobileMenu.propTypes = {
    shown: PropTypes.bool,
    searchOptions: PropTypes.object,
    toolbar: PropTypes.shape({
        toolbarItems: PropTypes.arrayOf(PropTypes.node).isRequired,
    }),
    pages: PropTypes.array,
};

export default MobileMenu;
