/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Italic tags
 * {i}Text to be italic{/i}
 */
export default new SimpleTag(
    'i',
    (key, contents) => {
        return <i key={key}>{contents}</i>;
    },
    (contents) => contents
);
