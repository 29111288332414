/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchShippingPolicy } from '~api/common/settings/shippingPolicy.js';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import deepFreeze from '~utils/objects/deepFreeze';


export const getShippingPolicy = createSimpleAPIAsyncThunk('shippingPolicy/getShippingPolicy', fetchShippingPolicy);


const initialState = deepFreeze({
    text: '',
});

const shippingPolicySlice = createSlice({
    name: 'shippingPolicy',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getShippingPolicy.fulfilled, (state, action) => {
            state.text = action.payload.text;
        });
    },
});


export default shippingPolicySlice.reducer;
