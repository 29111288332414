/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Component } from 'react';

import { getLogger } from '~utils/logging';

import ErrorPage from './errorPages/ErrorPage';

const logger = getLogger(Symbol('Components:Routing:RouteErrorBoundary'));

class RouteErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logger.error('Error caught by RouteErrorBoundary', { error, info });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

RouteErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default RouteErrorBoundary;
