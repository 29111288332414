/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { memo } from 'react';

import { addDebugMethod } from '~utils/debug';

import { automationIDPropTypes } from '../id/AutomationID';

import format from './rsaMessageFormat';
import { formattedStringParamtersPropType } from './rsaMessageFormat/propTypes';

const FormattedString = ({ automationID, pattern, parameters, source }) => {
    return (
        <span id={automationID?.id} className="formatted-string" data-source={source}>
            {format(pattern, parameters)}
        </span>
    );
};

FormattedString.propTypes = {
    automationID: automationIDPropTypes,
    pattern: PropTypes.string.isRequired,
    parameters: formattedStringParamtersPropType,
    source: PropTypes.string,
};

export default memo(FormattedString);

addDebugMethod('COMPONENTS', 'showFormattedStringSource', () => {
    document.body.classList.add('formatted-string-debug');
});
addDebugMethod('COMPONENTS', 'hideFormattedStringSource', () => {
    document.body.classList.remove('formatted-string-debug');
});
