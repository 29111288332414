/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import ScreenReaderOnlyText from '~components/a11y/ScreenReaderOnlyText';
import ContainerWithAction from '~components/buttons/ContainerWithAction';
import getEnhancedComponentName from '~components/getEnhancedComponentName';
import Tooltip, { PlacementPropType } from '~components/tooltip/Tooltip';
import useCallbackRef from '~utils/hooks/useCallbackRef';

/**
 * Wraps an Icon component type into an ContainerWithAction, with an appropriately accessible tooltip.
 *
 * THIS SHOULD NOT BE USED DIRECTLY.
 * See FeatherIcon and MaterialIcon for FeatherIconContainerWithAction and MaterialIconContainerWithAction exports.
 */
const withIconContainerWithAction = (WrappedComponent) => {
    const EnhancedComponent = ({ className, action, inline, notTabbable, tooltipPlacement, tooltip, ...iconProps }) => {
        const [tooltipRef, setTooltipRef] = useCallbackRef();
        return (
            <ContainerWithAction ref={setTooltipRef} className={className} action={action} inline={inline} notTabbable={notTabbable}>
                <WrappedComponent {...iconProps} />
                <Tooltip placement={tooltipPlacement} target={tooltipRef}>
                    {tooltip}
                </Tooltip>
                <ScreenReaderOnlyText text={tooltip} />
            </ContainerWithAction>
        );
    };

    EnhancedComponent.propTypes = {
        className: PropTypes.string,
        action: PropTypes.func,
        inline: PropTypes.bool,
        notTabbable: PropTypes.bool,
        tooltipPlacement: PlacementPropType,
        tooltip: PropTypes.node.isRequired,
    };

    EnhancedComponent.displayName = getEnhancedComponentName('WithIconContainerWithAction', WrappedComponent);

    return EnhancedComponent;
};

export default withIconContainerWithAction;