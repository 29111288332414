/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';

import ScreenReaderOnlyText from '~/components/a11y/ScreenReaderOnlyText';
import Button from '~components/buttons/Button';
import getEnhancedComponentName from '~components/getEnhancedComponentName';
import Tooltip, { PlacementPropType } from '~components/tooltip/Tooltip';
import useCallbackRef from '~utils/hooks/useCallbackRef';

const InlineIconButton = forwardRef(({ id, action, ariaLabel, tabIndex, children }, ref) => {
    const onClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        action && action();
    }, [action]);

    return (
        <a
            id={id}
            ref={ref}
            href="#"
            role="button"
            aria-label={ariaLabel}
            className={`icon-btn-inline${(!action && ' disabled') || ''}`}
            disabled={!action}
            tabIndex={tabIndex || (!action && '-1') || undefined}
            onClick={onClick}
        >
            {children}
        </a>
    );
});

InlineIconButton.displayName = 'InlineIconButton';

InlineIconButton.propTypes = {
    id: PropTypes.string,
    action: PropTypes.func,
    ariaLabel: PropTypes.string,
    tabIndex: PropTypes.string,
    children: PropTypes.node.isRequired,
};

/**
 * Wraps an Icon component type into an IconButton, with an appropriately accessible tooltip.
 *
 * THIS SHOULD NOT BE USED DIRECTLY.
 * See FeatherIcon and MaterialIcon for FeatherIconButton and MaterialIconButton exports.
 */
const withIconButton = (WrappedComponent) => {
    const EnhancedComponent = forwardRef(({
        action,
        id,
        type,
        small,
        block,
        inline,
        tooltipPlacement,
        tooltip,
        ariaLabel,
        tabIndex,
        ...iconProps
    }, ref) => {
        const [tooltipRef, setTooltipRef] = useCallbackRef();
        const updateRef = useCallback((newRef) => {
            if (ref && ref instanceof Function) {
                ref(newRef);
            }
            setTooltipRef(newRef);
        }, [ref, setTooltipRef]);
        const buttonContents = (
            <>
                <WrappedComponent {...iconProps} />
                <Tooltip placement={tooltipPlacement} target={tooltipRef}>
                    {tooltip}
                </Tooltip>
                <ScreenReaderOnlyText text={tooltip} />
            </>
        );


        if (inline) {
            return (
                <InlineIconButton ref={updateRef} id={id} action={action} tabIndex={tabIndex} ariaLabel={ariaLabel}>{buttonContents}</InlineIconButton>
            );
        }

        const buttonProps = { action, type, small, block, ariaLabel, tabIndex, id };
        return (
            <Button ref={updateRef} {...buttonProps}>
                {buttonContents}
            </Button>
        );
    });

    EnhancedComponent.propTypes = {
        id: PropTypes.string,
        action: PropTypes.func,
        type: PropTypes.string,
        small: PropTypes.bool,
        block: PropTypes.bool,
        inline: PropTypes.bool,
        tooltipPlacement: PlacementPropType,
        tooltip: PropTypes.node.isRequired,
        ariaLabel: PropTypes.string,
        tabIndex: PropTypes.string,
    };

    EnhancedComponent.displayName = getEnhancedComponentName('WithIconButton', WrappedComponent);

    return EnhancedComponent;
};

export default withIconButton;
