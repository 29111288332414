/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

const initialState = { pending: 0, hasFailure: false };

const ajaxStateSlice = createSlice({
    name: 'ajaxState',
    initialState,
    reducers: {
        // Unlike addressFields or other API actions which need to set or retrieve something from an API, this
        // is synchronous. Only client-side needs to know about this so we use a "standard reducer" to update the
        // state instantly
        setAJAXState(_state, action) {
            return action.payload;
        },
    },
});

export const { setAJAXState } = ajaxStateSlice.actions;
export default ajaxStateSlice.reducer;
