/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { forwardRef } from 'react';

import MenuContainer from './MenuContainer';

const PopupContainer = forwardRef(({ ...props }, ref) => {
    return <MenuContainer ref={ref} {...props} Tag="div" />;
});

PopupContainer.displayName = 'PopupContainer';

export default PopupContainer;
