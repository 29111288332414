/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get as fetchActiveSite, setID as pushActiveSiteID } from '~api/ordering/activeSite';
import { get as fetchSites } from '~api/ordering/sites';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import deepFreeze from '~utils/objects/deepFreeze';

import clientSideCache from '../helpers/clientSideCache';
import { handleUpdateOrderSuccess } from '../slices/orders';

const ACTIVE_ID_CACHE_KEY = 'sites-activeID';
const LIST_CACHE_KEY = 'sites-list';
const cachedActiveID = clientSideCache.loadShortTerm(ACTIVE_ID_CACHE_KEY, undefined);
const cachedList = clientSideCache.loadShortTerm(LIST_CACHE_KEY, []);

const initialState = deepFreeze({ activeID: cachedActiveID, list: cachedList });

export const getSites = createSimpleAPIAsyncThunk('sites/get', fetchSites);
export const getActiveSite = createSimpleAPIAsyncThunk('sites/getActiveSite', fetchActiveSite);

export const setActiveSiteID = createAsyncThunk(
    'sites/setActiveSiteID',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const response = await pushActiveSiteID(id);
            if (response) {
                if (response.updatedOrderID) {
                    handleUpdateOrderSuccess({
                        dispatch,
                        id: response.updatedOrderID,
                        appliedChanges: { site: { id } },
                        indirectUpdate: true,
                    });
                }
            }
            return {
                id,
                ...response,
            };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const sitesSlice = createSlice({
    name: 'sites',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getSites.fulfilled, (state, action) => {
            state.list = action.payload;
            clientSideCache.saveShortTerm(LIST_CACHE_KEY, state.list);
        });
        builder.addCase(getActiveSite.fulfilled, (state, action) => {
            state.activeID = action.payload.activeSiteID;
            clientSideCache.saveShortTerm(ACTIVE_ID_CACHE_KEY, state.activeID);
        });
        builder.addCase(setActiveSiteID.fulfilled, (state, action) => {
            state.activeID = action.payload.id;
            clientSideCache.saveShortTerm(ACTIVE_ID_CACHE_KEY, state.activeID);
        });
    },
});

export default sitesSlice.reducer;
