/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef, memo, useCallback } from 'react';
import { Button as BButton } from 'reactstrap';

import withAPICall from './withAPICall';

const BUTTON_TYPE = Object.freeze({
    primary: 'primary',
    secondary: 'secondary',
    standard: 'outline-primary',
    muted: 'outline-secondary',
    mutedSolid: 'secondary',
    mutedOnDark: 'white btn-outline-secondary',
    danger: 'outline-danger',
    link: 'link-primary',
    linkDanger: 'link-danger',
    linkSecondary: 'link-secondary',
    linkOnDark: 'link-white',
    tile: 'tile',
});

const getColorFromType = (type) => {
    return BUTTON_TYPE[type] || BUTTON_TYPE.standard;
};

const getClassName = ({ block, inline, className, disabled }) => {
    const classes = [];
    if (className) {
        classes.push(className);
    }
    if (block) {
        classes.push('btn-block');
    } else if (inline) {
        classes.push('btn-inline');
    }
    if (disabled) {
        classes.push('disabled');
    }
    return classes.length === 0 ? undefined : classes.join(' ');
};

// TODO 987 - Eventually change 'id' into an 'automationID' then trace every use of Button and pass one in so we don't
// need to do the automation && automation.id check everywhere. Also do a search for other uses (auto && auto.id) in the code
// and change those into automationIDs too. Like checkbox.


/**
 * A Button or Button-like component.
 * Note: See FeatherIcon and MaterialIcon for buttons that contain only an icon, to ensure proper accessibility.
 */
const Button = forwardRef(({ id, children, action, type, small, block, inline, className, ariaLabel, ariaDescription, tooltip, tabIndex }, ref) => {
    const disabled = !action;
    const onClick = useCallback((e) => {
        // We want clicking on a button to do nothing except the specified action.
        // Even if a button is disabled, it shouldn't bubble up to any potentially clickable parent elements, nor
        // should it attempt to submit the form it is included in or perform any other browser-defined behavior.
        e.preventDefault();
        e.stopPropagation();
        if (!disabled) {
            action(e);
        }
    }, [action, disabled]);

    return (
        <BButton
            id={id}
            className={getClassName({ block, inline, className, disabled })}
            innerRef={ref}
            color={getColorFromType(type)}
            size={small ? 'sm' : null}
            onClick={onClick}
            aria-label={ariaLabel}
            // eslint-disable-next-line jsx-a11y/aria-props
            aria-description={ariaDescription}
            // Omit the aria-disabled attribute if not disabled instead of explicitly setting it to false.
            // While this shouldn't matter in practice, it is slightly safer to not set it just in case something else outside
            // of our control comes along and sets the disabled attribute (as opposed to aria-disabled) for some other reason.
            // Why do we use aria-disabled instead of disabled? Better accessibility. The disabled attribute stops disabled
            // buttons from receiving tab focus, making it harder for screen reader users to know that the option exists but is
            // temporarily disabled. By using aria-disabled instead, the button can receive tab focus and a screen reader will
            // announce the button and state that it is disabled, giving screen reader users more direct access to the same
            // information that sighted users receive.
            aria-disabled={disabled ? true : undefined}
            title={tooltip}
            tabIndex={tabIndex}
        >
            {children}
        </BButton>
    );
});
Button.displayName = 'Button';

Button.propTypes = {
    id: PropTypes.string,
    ariaLabel: PropTypes.string,
    ariaDescription: PropTypes.string,
    children: PropTypes.node.isRequired,
    action: PropTypes.func,
    type: PropTypes.oneOf(Object.keys(BUTTON_TYPE)),
    small: PropTypes.bool,
    block: PropTypes.bool,
    inline: PropTypes.bool,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    tabIndex: PropTypes.string,
};

export default memo(Button);

/**
 * A specialized button that will make an API call and execute actions for success cases
 * Also it will disable the button while the API call is in progress.
 */
export const ButtonWithAPIAction = withAPICall(Button);
