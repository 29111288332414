/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Superscript tags
 * {sup}Text to show as superscript{/sup}
 */
export default new SimpleTag(
    'sup',
    (key, contents) => {
        return <sup key={key}>{contents}</sup>;
    },
    (contents) => contents
);
