/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useMemo } from 'react';

import { useCurrentUser } from '~/components/user/UserContext';

const useTopEntryHeaderOptions = () => {
    const currentUser = useCurrentUser();

    // TODO JAS webcrd_dev-34 Pull email/phone from the server
    const email = currentUser?.features?.login?.email;
    const phone = currentUser?.features?.login?.phone;
    const printShopHours = currentUser?.features?.login?.printShopHours;
    return useMemo(
        () => ({
            printShopHours,
            email,
            phone,
        }),
        [email, phone, printShopHours]
    );
};

export default useTopEntryHeaderOptions;
