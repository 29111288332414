/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Changes the font family of text
 * {fontFamily=roboto}Text to use the Roboto font{/fontFamily}
 */
export default new SingleAttributeTag(
    'fontFamily',
    validAttributePattern,
    (key, attributeValue, contents) => {
        return (
            <span key={key} style={{ fontFamily: attributeValue }}>
                {contents}
            </span>
        );
    },
    (_attributeValue /* unused for text formatting */, contents) => contents
);
