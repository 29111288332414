/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { Navigate, Route } from 'react-router-dom';

import LazyRouteContents from './lazyRouting/LazyRouteContents';

export const buildLazyRoute = (path, lazyLoader, titleLocaleKey) => {
    return <Route key={path} path={path} element={<LazyRouteContents path={path} lazyLoader={lazyLoader} titleLocaleKey={titleLocaleKey} isFullPage />}/>;
};

export const buildRedirectRoute = (path, to) => {
    return <Route key={path} path={path} element={<Navigate to={to} />}/>;
};
