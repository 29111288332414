/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { getBookList as fetchBookList } from '~/api/ordering/books';
import { get as fetchActiveBook } from '~/api/ordering/books/activeBook';
import { getBookVersions as fetchBookVersions } from '~/api/ordering/books/book';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';
import deepFreeze from '~utils/objects/deepFreeze';

export const getBooks = createSimpleAPIAsyncThunk('getBooks', fetchBookList);
export const getActiveBook = createSimpleAPIAsyncThunk('getActiveBook', fetchActiveBook);
export const getBookVersions = createSimpleAPIAsyncThunk('getBookVersions', fetchBookVersions);

const initialState = deepFreeze({
    list: null,
    activeBook: undefined, // null if no active book - undefined if we haven't gotten info yet.
    versions: undefined, // null if no versions - undefined if we haven't gotten info yet.
});

const bookSlice = createSlice({
    name: 'books',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getBooks.fulfilled, (state, action) => {
            state.list = action.payload;
        });
        builder.addCase(getActiveBook.fulfilled, (state, action) => {
            state.activeBook = action.payload;
        });
        builder.addCase(getBookVersions.fulfilled, (state, action) => {
            state.versions = action.payload;
        });
        builder.addCase(getBookVersions.rejected, (state) => {
            state.versions = undefined;
        });
    },
});


export default bookSlice.reducer;
