/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk } from '@reduxjs/toolkit';

import APIError from '~/api/APIError';

/**
 * Creates an AsyncThunk for an APICall, where the argument for the thunk action matches the argument for the API call,
 * with no extra logic needed to parse/sanitize/filter/etc the argument or api response.
 */
export default (typePrefix, apiCall) => {
    return createAsyncThunk(
        typePrefix,
        async (arg, { rejectWithValue }) => {
            try {
                return await apiCall(arg);
            } catch (error) {
                // The error should always be an APIError type... but we support other thrown errors to be a little more graceful if there is a bug
                const errorDetails = {};

                if (typeof error === 'string') {
                    errorDetails.message = error;
                } else {
                    errorDetails.message = error.message;
                }

                if (error instanceof APIError) {
                    errorDetails.apiErrorType = error.type;

                    // Not needed by the code, but can be useful for debugging errors
                    errorDetails.requestID = error.requestID;
                }

                // Send a package of error data back to the slices
                return rejectWithValue(errorDetails);
            }
        }
    );
};