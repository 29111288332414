/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Right alignment tags
 * {right}Text to be right aligned{/right}
 */
export default new SimpleTag(
    'right',
    (key, contents) => {
        return (
            <div key={key} style={{ textAlign: 'right' }}>
                {contents}
            </div>
        );
    },
    (contents) => `\n${contents}\n`
);
