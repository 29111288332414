/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useMemo } from 'react';

import APIError from '~api/APIError';
import LocalizedString from '~components/text/LocalizedString';
import useLocalizations from '~components/text/useLocalizations';
import { getLogger } from '~utils/logging';

const logger = getLogger(Symbol('Components:Alert:APIErrorMessage'));

const ERROR_LIKELY_LOST_CONNECTION = 'COMMON_UI.ERROR.LIKELY_LOST_CONNECTION';
const ERROR_UNEXPECTED_NO_ID = 'COMMON_UI.ERROR.UNEXPECTED_ERROR';
const ERROR_UNEXPECTED_WITH_ID = 'COMMON_UI.ERROR.UNEXPECTED_ERROR_WITH_ID';

export const useLocalizationPrefetch = () => {
    useLocalizations([ERROR_LIKELY_LOST_CONNECTION, ERROR_UNEXPECTED_NO_ID, ERROR_UNEXPECTED_WITH_ID]);
};

const APIErrorMessage = ({ apiError }) => {
    // We should only ever get APIErrors... but it's an exception path, so it's already acting outside of the normal behavior.
    // We still want to at least be semi-graceful about it if some mistake causes a different error type to be passed in.
    let requestID;
    let type;
    if (apiError instanceof APIError) {
        type = apiError.type;
        requestID = apiError.requestID;
    } else {
        logger.warn('Unexpected Error passed to APIErrorMessage', { apiError });
        type = APIError.UNEXPECTED_CLIENT_ERROR;
    }

    const parameters = useMemo(() => {
        if (requestID) {
            return {
                ERROR_ID: requestID,
            };
        } else {
            return undefined;
        }
    }, [requestID]);

    let localeKey;
    switch (type) {
        case APIError.TYPE.UNEXPECTED_RESPONSE: // no break
        case APIError.TYPE.UNAUTHORIZED:
            localeKey = ERROR_LIKELY_LOST_CONNECTION;
            break;
        case APIError.TYPE.NOT_FOUND: // no break
        case APIError.TYPE.BAD_REQUEST: // no break
        case APIError.TYPE.UNEXPECTED_CLIENT_ERROR: // no break
        case APIError.TYPE.SERVER_ERROR: // no break
        default:
            if (requestID) {
                localeKey = ERROR_UNEXPECTED_WITH_ID;
            } else {
                localeKey = ERROR_UNEXPECTED_NO_ID;
            }
            break;
    }
    return <LocalizedString localeKey={localeKey} parameters={parameters} />;
};

APIErrorMessage.propTypes = {
    apiError: PropTypes.instanceOf(APIError),
};

export default APIErrorMessage;
