/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import Tag from './tag';

export default class SimpleTag extends Tag {
    constructor(tagName, renderer, textRenderer) {
        super(tagName);
        this.renderer = renderer;
        this.textRenderer = textRenderer;
    }

    renderTag({ parsedTag, keyGen, renderContents }) {
        return this.renderer(keyGen.next, renderContents(parsedTag.contents));
    }

    renderTagAsText({ parsedTag, renderContents }) {
        return this.textRenderer(renderContents(parsedTag.contents));
    }
}
