/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { withKeyModifier } from '~utils/clientSideStorage';

export default withKeyModifier((key) => {
    return `store-cache--${key}`;
});