/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { configureStore } from '@reduxjs/toolkit';

import { watchUpdates } from '~api/updateStatus';
import commonReducers from '~common-store/slices/';

import { setAJAXState } from './slices/ajaxState';

// This is used as as a starting point for creating our main store. Since all these slices/reducers should be available
// to all roles we create the store here. Then we add the other reducers that were passed into this function from the
// other file based on the current role we are in:
// -- src/roles/ordering/store/index.js
// -- src/roles/catalog/store/index.js
// -- src/roles/public/store/index.js
// -- src/roles/style-guide/store/index.js
// The store is then created with the common reducers and role specific reducers and the entire store is exported
// and returned all the way down to the App.js of the current role, where it is initialized as the store for the application
// A Redux DevTools chrome extension is available which is very handy in debugging:
//     https://github.com/reduxjs/redux-devtools/tree/main/extension#1-for-chrome
export default ({ reducers }) => {
    const store = configureStore({
        reducer: {
            ...commonReducers,
            ...reducers,
        },
    });

    watchUpdates((ajaxState) => {
        store.dispatch(setAJAXState(ajaxState));
    });

    return store;
};
