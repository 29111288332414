/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

const HighlightedText = ({ children }) => {
    return <span className="highlighted-text">{children}</span>;
};

HighlightedText.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HighlightedText;
