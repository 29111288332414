/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import useLocalizationTextFormatted from '~components/text/useLocalizationTextFormatted';

import DefsSVG from './DefsSVG';

const outerSpinDuration = '2s';
const innerSpinDuration = '2s';
const circleScales = [1, 0.88, 0.77, 0.68, 0.6, 0.53, 0.46, 0.4].map((opacity) => {
    const halfSpline = `${(2 - opacity) * 0.3} 0 ${(3 - opacity) * 0.3} 1`;
    return {
        opacity,
        outerSplines: `${halfSpline};${halfSpline}`,
        innerTransform: `rotate(${opacity * -75} 50 50)`,
    };
});

const rotations = [0, 120, 240];

const OUTER_ID = 'SpinnerOuter';
const INNER_ID = 'SpinnerInner';

export const SpinnerDefs = () => {
    return (
        <DefsSVG>
            <g id={OUTER_ID} fill="var(--c-primary)" opacity={0.5}>
                {circleScales.map((s, index) => (
                    <circle key={index} cx="50" cy="10" r="9" opacity={s.opacity}>
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            calcMode="spline"
                            keySplines={s.outerSplines}
                            values="0 50 50;180 50 50;360 50 50"
                            dur={outerSpinDuration}
                            repeatCount="indefinite"
                        />
                    </circle>
                ))}
            </g>
            <g id={INNER_ID} fill="var(--c-primary)">
                {circleScales.map((s, index) => (
                    <circle key={index} cx="50" cy="30" r="5" opacity={s.opacity} transform={s.innerTransform} />
                ))}
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    from="360 50 50"
                    to="0 50 50"
                    dur={innerSpinDuration}
                />
            </g>
        </DefsSVG>
    );
};

const Spinner = ({ size }) => {
    const loadingLabel = useLocalizationTextFormatted('COMMON_UI.IMGALT.SKELETON_SPINNER');
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 100 100"
            role="progressbar"
            aria-valuetext={loadingLabel}
            aria-busy="true"
            aria-live="polite"
            aria-valuemin="0"
            aria-valuemax="100"
        >
            {rotations.map((r, i) => (
                <g key={i} transform={r === 0 ? undefined : `rotate(${r} 50 50)`}>
                    <use xlinkHref={`#${OUTER_ID}`} />
                    <use xlinkHref={`#${INNER_ID}`} />
                </g>
            ))}
        </svg>
    );
};

Spinner.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Spinner;
