/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { getLogger } from '~utils/logging';

import { addDebugMethod } from './debug';

const logger = getLogger(Symbol('Utils:ClientSideStorage'));

const ENABLE_KEY = 'utils.clientSideStorage.enabled';

const getFullKey = (key) => {
    return `webcrd--${key}`;
};

/**
 * Loads a value from the provided storage for the given key, returning the fallback if no value was saved in that storage.
 *
 * @param {Storage} storage - localStorage or sessionStorage
 */
const _load = (storage, key, fallback) => {
    const serializedState = storage.getItem(getFullKey(key));
    if (serializedState === null) {
        if (logger.isTraceEnabled()) {
            logger.trace(`State for ${key} was not saved`);
        }
        return fallback;
    }
    return JSON.parse(serializedState);
};

/**
 * Saves a value to the provided storage for the given key
 *
 * @param {Storage} storage - localStorage or sessionStorage
 */
const _save = async (storage, key, state) => {
    const fullKey = getFullKey(key);
    if (state === undefined) {
        logger.error('Attempt to save undefined to client side storage', { key });
        storage.removeItem(fullKey);
    } else {
        const serializedState = JSON.stringify(state);
        storage.setItem(fullKey, serializedState);
    }
};

/**
 * Loads a value from sessionStorage for the given key, returning the fallback if no value was saved in localStorage
 */
export const loadShortTerm = (key, fallback) => {
    return _load(sessionStorage, key, fallback);
};

/**
 * Saves a value to sessionStorage for the given key
 */
export const saveShortTerm = async (key, state) => {
    _save(sessionStorage, key, state);
};

let enableStorage = loadShortTerm(ENABLE_KEY, true);
if (!enableStorage) {
    logger.error('CLIENT SIDE STORAGE DISABLED');
}

/**
 * Debug Method: DEBUG.CLIENT_SIDE_STORAGE.clear() - completely empties localStorage
 */
addDebugMethod('CLIENT_SIDE_STORAGE', 'clear', () => {
    logger.error('CLIENT SIDE STORAGE CLEARED');
    localStorage.clear();
});

/**
 * Debug Method: DEBUG.CLIENT_SIDE_STORAGE.clearShortTerm() - completely empties sessionStorage
 */
addDebugMethod('CLIENT_SIDE_STORAGE', 'clearShortTerm', () => {
    logger.error('CLIENT SIDE STORAGE CLEARED');
    sessionStorage.clear();
});

/**
 * Debug Method: DEBUG.CLIENT_SIDE_STORAGE.disable() - Causes save to never save to localStorage, and load to always return the fallback.
 */
addDebugMethod('CLIENT_SIDE_STORAGE', 'disable', () => {
    logger.error('CLIENT SIDE STORAGE DISABLED');
    enableStorage = false;
    saveShortTerm(ENABLE_KEY, enableStorage);
});

/**
 * Debug Method: DEBUG.CLIENT_SIDE_STORAGE.enable() - Enables save and load behavior.
 */
addDebugMethod('CLIENT_SIDE_STORAGE', 'enable', () => {
    logger.error('CLIENT SIDE STORAGE ENABLED');
    enableStorage = true;
    saveShortTerm(ENABLE_KEY, enableStorage);
});

/**
 * Loads a value from localStorage for the given key, returning the fallback if no value was saved in localStorage
 */
export const load = (key, fallback) => {
    if (!enableStorage) {
        return fallback;
    }
    return _load(localStorage, key, fallback);
};

/**
 * Saves a value to localStorage for the given key
 */
export const save = async (key, state) => {
    if (!enableStorage) {
        return;
    }
    _save(localStorage, key, state);
};

/**
 * Removes any previously saved value from localStorage for the given key
 */
export const reset = async (key) => {
    if (!enableStorage) {
        return;
    }
    localStorage.removeItem(getFullKey(key));
};

/**
 * @typedef {function(string):string} StorageKeyModifier
 */

/**
 * @typedef ClientSideStorage
 * @property {function(string,*):*} load (key, fallback) => Loads a value from localStorage for the given key, returning the fallback if no value was saved in localStorage
 * @property {function(string,*):void} save (key, state) => Saves a value to localStorage for the given key
 * @property {function(string):void} reset (key) => Removes any previously saved value from localStorage for the given key
 * @property {function(string,*):*} loadShortTerm (key, fallback) => Loads a value from sessionStorage for the given key, returning the fallback if no value was saved in sessionStorage
 * @property {function(string,*):void} saveShortTerm (key, state) => Saves a value to sessionStorage for the given key
 * @property {function(StorageKeyModifier):ClientSideStorage} withKeyModifier (keyModifier) => A clientSideStorage instance with a secondary keyModifier (the child modifier applies before the parent)
 */

/**
 * Builds a clientSideStorage instance with a key modifier
 *
 * @param {StorageKeyModifier} keyModifier - Function that takes a key string and modifies it (ex: adding a prefix)
 * @returns {ClientSideStorage} A ClientSideStorage instance with the given key modifier
 */
export const withKeyModifier = (keyModifier) => {
    return Object.freeze({
        load: (key, fallback) => {
            return load(keyModifier(key), fallback);
        },
        save: async (key, state) => {
            save(keyModifier(key), state);
        },
        reset: async (key) => {
            reset(keyModifier(key));
        },
        loadShortTerm: (key, fallback) => {
            return loadShortTerm(keyModifier(key), fallback);
        },
        saveShortTerm: async (key, state) => {
            saveShortTerm(keyModifier(key), state);
        },
        withKeyModifier: (childKeyModifier) => {
            return withKeyModifier((key) => keyModifier(childKeyModifier(key)));
        },
    });
};