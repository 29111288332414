/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

export const CLICK_LISTENER_OPTIONS = Object.freeze({
    capture: true,
    passive: false,
});

const isClickInside = ({ event, parent }) => {
    let element = event.target;
    while (element && element !== parent) {
        element = element.parentNode;
    }
    return element === parent;
};

export default isClickInside;