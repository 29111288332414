/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import debounce from 'debounce';

import { addDebugMethod } from '~utils/debug';

const UPDATE_WATCHERS_DEBOUNCE_MS = 50;

const updateWatchers = [];

/**
 * Register a listener to be notified when the API update status changes
 *
 * @param {Function} callback - The function to call when the API update status changes. The listener will be called with a single object argument...
 *     {
 *         pending: {integer count of the number of pending updates},
 *         hasFailures: {boolean flag indicating if at least one request responded with an error}
 *     }
 */
export const watchUpdates = (callback) => {
    updateWatchers.push(callback);
};

let hasFailures = false;
let pendingUpdates = 0;

/**
 * Record that an API update has been sent, and we are waiting on a response from the server.
 */
export const increasePendingUpdates = () => {
    pendingUpdates++;
    notifyUpdateWatchers();
};

/**
 * Record that an API update response has been received from the server.
 *
 * @param {boolean} success - Flag indicating if the response was a success response or not (a request that was processed, but didn't update due to
 *                user input validation, is still a success - failures are for unexpected failures only: network interruptions, 404s, etc)
 */
export const decreasePendingUpdates = (success) => {
    pendingUpdates--;
    if (!success) {
        hasFailures = true;
    }
    notifyUpdateWatchers();
};

/**
 * Calls the update status listeners (registered via watchUpdates).
 * Notifications are throttled to happen at most once every UPDATE_WATCHERS_DEBOUNCE_MS milliseconds, to minimize the overhead of
 * several API calls being made at the same time.
 */
const notifyUpdateWatchers = debounce(() => {
    for (const updateWatcher of updateWatchers) {
        updateWatcher({ pending: pendingUpdates, hasFailures });
    }
}, UPDATE_WATCHERS_DEBOUNCE_MS);

/**
 * Register a debug method for simulating API status updates...
 * DEBUG.API.fakeAPIStatus(fakePendingUpdates, fakeHasFailures)
 *   fakePendingUpdates: A number indicating how many API requests are current in progress
 *   fakeHasFailures: A boolean flag indicating if at least one API request responded with an failure
 */
addDebugMethod('API', 'fakeAPIStatus', (fakePendingUpdates, fakeHasFailures) => {
    pendingUpdates = fakePendingUpdates || 0;
    hasFailures = !!fakeHasFailures;
    notifyUpdateWatchers();
});
