/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { isValidList, renderListItems, renderListItemsAsText } from './listItem';
import * as rawTagRenderer from './rawTagRenderer';
import Tag from './tag';

/**
 * Unordered (bulletted) lists
 * {ul}{li}First list item{/li}{li}Second list item{/li}...{/ul}
 */
class UnorderedList extends Tag {
    constructor() {
        super('ul');
    }

    renderTag({ parsedTag, keyGen, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidList(contents)) {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        return <ul key={keyGen.next}>{renderListItems({ contents, keyGen, renderContents })}</ul>;
    }

    renderTagAsText({ parsedTag, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidList(contents)) {
            return rawTagRenderer.renderAsText(parsedTag, renderContents);
        }
        return `\n${renderListItemsAsText({ contents, renderContents })}\n`;
    }
}

export default new UnorderedList();
