/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import TypedInput from './TypedInput';

const TextInput = (props) => {
    return <TypedInput type="text" {...props} />;
};

export default TextInput;
