/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import IconBase from './IconBase';
import withIconButton from './withIconButton';
import withIconContainerWithAction from './withIconContainerWithAction';
import withIconTooltip from './withIconTooltip';

const normalizeIconName = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
};

/**
 * See https://fonts.google.com/icons for a list of icons
 */
const MaterialIcon = ({ name, ...iconBaseProps }) => {
    return <IconBase className="material-icons" {...iconBaseProps}>{normalizeIconName(name)}</IconBase>;
};

MaterialIcon.propTypes = {
    name: PropTypes.string.isRequired,
};

export default MaterialIcon;

export const MaterialIconButton = withIconButton(MaterialIcon);
export const MaterialIconContainerWithAction = withIconContainerWithAction(MaterialIcon);
export const MaterialIconWithTooltip = withIconTooltip(MaterialIcon);
