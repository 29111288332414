/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';

import SimpleToken from './simpleToken';

const NBSP = ' ';

/**
 * Shows a non-breaking space character
 * {nbsp}
 */
export default new SimpleToken(
    'nbsp',
    (key) => {
        return <Fragment key={key}>{NBSP}</Fragment>;
    },
    NBSP
);
