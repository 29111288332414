/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useMemo } from 'react';


import { useAddToast } from '~components/alerts/ToastContext';
import getEnhancedComponentName from '~components/getEnhancedComponentName';
import FormattedString from '~components/text/FormattedString';
import { getLogger } from '~utils/logging';

import withWaitForAction from './withWaitForAction';

const logger = getLogger(Symbol('Components:Buttons:withAPICall'));


/**
 * Wraps a component in functionality that will execute an API call as the "action" and then execute a specified success
 * action depending on the response.
 *
 * THIS SHOULD NOT BE USED DIRECTLY.
 * See ContainerWithAPIAction export.
 */
const withAPICall = (WrappedComponent) => {
    const EnhancedComponent = ({
        apiCall,
        successAction,
        ...componentProps
    }) => {

        // Wrap this component so now whatever action we pass into it will disable the button during execution
        const ComponentWithWaitAction = withWaitForAction(WrappedComponent);
        const addToast = useAddToast();
        const componentAction = useMemo(() => {
            if (!apiCall) {
                return undefined;
            }

            // Expand on our original API call into one that will make that API call, wait for a response,
            // then execute a success or failure action afterwards
            return async() => {
                const response = await apiCall();
                if (response === undefined) {
                    // In some cases we might want to execute non-API actions that are just validation errors found
                    // on the client side. As long our "display validation errors" actions also return a success
                    // true we won't get this warning.
                    logger.warn('An non-API action was executed with "withAPICall". Was this intentional?');
                } else {
                    if (response.success) {
                        successAction();
                    }

                    // The API call will return a messages array with server-side messages
                    printMessages(addToast, response);
                }
            };
        }, [apiCall, successAction, addToast]);

        // Then we take that expanded upon action and just stick it into the wrapped component as an action

        // WrappedComponent right now is a Button but it could be other components that
        // also support the action props
        return <ComponentWithWaitAction action={componentAction} {...componentProps}/>;
    };

    EnhancedComponent.propTypes = {
        apiCall: PropTypes.func,
        successAction: PropTypes.func,
    };

    EnhancedComponent.displayName = getEnhancedComponentName('WithAPICall', WrappedComponent);

    return EnhancedComponent;
};

const printMessages = (addToast, response) => {
    // Print messages, if any, from the server
    if (response.messages) {
        for (const message of response.messages) {
            addToast({
                type: message.type,
                message: (
                    <FormattedString
                        pattern={message.message}
                        source="withAPICall response.messages.message"
                    />
                ),
            });
        }
    }
};

export default withAPICall;
