/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { useGetBrowserTitleAdditionalName, useGetBrowserTitleLocaleKey } from '../header/help/HelpContext';
import LocalizedParameter from '../text/rsaMessageFormat/LocalizedParameter';
import useLocalizationTextFormatted from '../text/useLocalizationTextFormatted';
import { withUserData } from '../user/UserContext';

const appNameParameter = new LocalizedParameter('COMMON_UI.TITLE.APPLICATION_TITLE');

const BrowserTitle = ({ userID }) => {

    // Browser titles have to be built in multiple steps:
    //  - There is a hardcoded name for the page itself. This is provided when the route is built so it cannot be forgotten.
    //  - Sometimes there is additional information to pair with that page, like an order or item name to give it more context. This is
    //       set on the page component itself because it needs to be loaded from the server and it's dynamic.
    //  - Finally there is overall format for the title where we arrange that page title above, the application name, and the logged in
    //       user into a friendly complete title. This entire format is localizable.
    //  - We also need to take into account cases where there is no additional information or no logged in user. We don't want the title to
    //       be something like 'Item Details -' with a hyphen pointing no where so we have a NO_NAME and NO_USER localization string where the
    //       name or user name is not included in the final title.

    const browserTitleLocaleKey = useGetBrowserTitleLocaleKey();
    const browserTitleAdditionalName = useGetBrowserTitleAdditionalName();

    const baseTitleKey = userID ? 'COMMON_UI.BROWSERTITLE.BASE_TITLE' : 'COMMON_UI.BROWSERTITLE.BASE_TITLE_NO_USER';
    const basePageTitleKey = browserTitleAdditionalName ? 'COMMON_UI.BROWSERTITLE.BASE_PAGE_TITLE' : 'COMMON_UI.BROWSERTITLE.BASE_PAGE_TITLE_NO_NAME';

    // The state of browserTitleLocaleKey is blank by default, so we need to set it to something else (UNKNOWN) or it wont update for unknown pages
    // Ex: "Item Details"
    const pageName = useLocalizationTextFormatted(browserTitleLocaleKey || 'UNKNOWN');

    // Ex: "Item Details"
    // EX: "Item Details - Red Pens"
    const basePageTitle = useLocalizationTextFormatted(basePageTitleKey, {
        PAGE_NAME: pageName,
        // Not always used, but if provided try to put it into the locale string
        NAME: browserTitleAdditionalName,
    });

    // Ex: "Item Details - Red Pens | WebCRD -  User [admin]"
    // EX: "Login | WebCRD"
    const baseTitle = useLocalizationTextFormatted(baseTitleKey, {
        PAGE_TITLE: basePageTitle,
        APPLICATION_TITLE: appNameParameter,
        USER_ID: userID,
    });

    // Final Format: {PAGE_NAME} - {ADDITIONAL_NAME} | {APPLICATION_NAME} - User [{USER_NAME}]
    // Where ADDITIONAL_NAME and USER_NAME are optional
    useEffect(() => {
        document.title = baseTitle || 'WebCRD';
    }, [baseTitle, browserTitleLocaleKey, browserTitleAdditionalName]);

    return null;
};

BrowserTitle.propTypes = {
    userID: PropTypes.string,
};

export default withUserData((user) => ({
    userID: user && (user.actualUserID || user.userID),
}))(BrowserTitle);
