/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { AlertType } from '~/components/alerts/AlertType';
import { useAddToast } from '~components/alerts/ToastContext';
import { addDebugMethod, setDebugState, useDebugState } from '~utils/debug';
import { loadDebugState, setDebugStateWithStorage } from '~utils/debugWithStorage';

const DEBUG_CATEGORY = 'WEB_HELP';
const DEBUG_SHOW_KEYS = 'showKey';
loadDebugState(DEBUG_CATEGORY, DEBUG_SHOW_KEYS, false);
addDebugMethod(DEBUG_CATEGORY, 'showKey', (save = true) => {
    const set = save ? setDebugStateWithStorage : setDebugState;
    set(DEBUG_CATEGORY, DEBUG_SHOW_KEYS, true);
});
addDebugMethod(DEBUG_CATEGORY, 'hideKey', (save = true) => {
    const set = save ? setDebugStateWithStorage : setDebugState;
    set(DEBUG_CATEGORY, DEBUG_SHOW_KEYS, false);
});

const sanitizeKeyForPropertiesFile = (webHelpKey) => {
    return webHelpKey.replace(/([:= \\])/g, '\\$1');
};

const useCopyTextCallback = (textToCopy) => {
    const addToast = useAddToast();
    return useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            navigator.clipboard.writeText(textToCopy);
            addToast({
                type: AlertType.SUCCESS,
                message: 'Text copied to clipboard',
                timeout: 1000,
            });
            return false;
        },
        [addToast, textToCopy]
    );
};

const HelpKeyDebug = ({ webHelpKey }) => {
    const sanitizedKey = sanitizeKeyForPropertiesFile(webHelpKey);
    const copyElementText = useCopyTextCallback(sanitizedKey);
    const showKeys = useDebugState(DEBUG_CATEGORY, DEBUG_SHOW_KEYS);

    if (!showKeys) {
        return null;
    }

    return (
        // We don't care about accessibility here, because it's just a debug function specifically for RSA use
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span className="debug--help-key" onClick={copyElementText}>
            {sanitizedKey}
        </span>
    );
};

HelpKeyDebug.propTypes = {
    webHelpKey: PropTypes.string.isRequired,
};

export default HelpKeyDebug;
