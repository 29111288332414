/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { StrictMode, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';

import { setTimeZone } from '~common-store/slices/timeZone';
import ScrollToTop from '~components/a11y/ScrollToTop';
import SkipToMainContent from '~components/a11y/SkipToMainContent';
import { ToastProvider } from '~components/alerts/ToastContext';
import { HelpProvider } from '~components/header/help/HelpContext';
import { SpinnerDefs } from '~components/skeleton/Spinner';
import { UserProvider } from '~components/user/UserContext';
import { DebugProvider } from '~utils/debug';

import { ARIALiveProvider } from '../a11y/ARIALiveProvider';

import AppErrorBoundary from './AppErrorBoundary';

/**
 * Initializes the TimeZone for the user on the server
 */
const TimeZoneInitializer = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setTimeZone());
    }, [dispatch]);

    return null;
};

/**
 * Wraps the core App component with the providers and other universally needed components for all roles.
 * See also RootRouter for some additional wrappers that depend on the current Route.
 *
 * StrictMode: Enables React Strict Mode tohelp detect potential problems earlier in development - see https://reactjs.org/docs/strict-mode.html
 * Provider: Redux store provider
 * AppErrorBoundary: A last-ditch error boundary to allow us to provide a reasonable error page if things go horribly wrong.
 * DebugProvider: A provider for some of the Debug tools added by RSA for testing and diagnosing issues.
 * UserProvider: A provider for details about the currently logged in user
 * HelpProvider: A provider for the WebHelp system
 * ToastProvider: A provider to allow showing Toasts (messages that pop up in the corner of the browser window)
 * TimeZoneInitializer: See above
 * SkipToMainContent: An accessibility helper for keyboard users to allow them to quickly skip over the navigation and into the Main Content
 * ScrollToTop: An accessibility helper to scroll the window back up to the top of the page
 * SpinnerDefs: A performance improvement for the loading Spinner skeleton
 */
const AppWrapper = ({ children, store }) => {
    return (
        <StrictMode>
            <Provider store={store}>
                <AppErrorBoundary>
                    <DebugProvider>
                        <UserProvider>
                            <HelpProvider>
                                <ToastProvider>
                                    <ARIALiveProvider>
                                        <TimeZoneInitializer />
                                        <SkipToMainContent />
                                        {children}
                                        <ScrollToTop />
                                        <SpinnerDefs />
                                    </ARIALiveProvider>
                                </ToastProvider>
                            </HelpProvider>
                        </UserProvider>
                    </DebugProvider>
                </AppErrorBoundary>
            </Provider>
        </StrictMode>
    );
};

AppWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    store: PropTypes.any,
};

export default AppWrapper;
