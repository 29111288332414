/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import * as rawTagRenderer from './rawTagRenderer';
import Tag from './tag';

export default class SingleAttributeTag extends Tag {
    constructor(tagName, validAttributePattern, renderer, textRenderer) {
        super(tagName);
        this.validAttributePattern = validAttributePattern;
        this.renderer = renderer;
        this.textRenderer = textRenderer;
    }

    renderTag({ parsedTag, keyGen, renderContents }) {
        if (parsedTag.tagData.length < 2 || parsedTag.tagData[0] !== '=') {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        const attributeValue = parsedTag.tagData.slice(1).join('');
        if (!this.validAttributePattern.test(attributeValue)) {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        return this.renderer(keyGen.next, attributeValue, renderContents(parsedTag.contents));
    }

    renderTagAsText({ parsedTag, renderContents }) {
        const attributeValue = parsedTag.tagData.slice(1).join('');
        if (!this.validAttributePattern.test(attributeValue)) {
            return rawTagRenderer.renderAsText(parsedTag, renderContents);
        }
        return this.textRenderer(attributeValue, renderContents(parsedTag.contents));
    }
}
