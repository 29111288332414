/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLocalization } from '~common-store/slices/localization';

const useLocalization = (localeKey) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getLocalization(localeKey));
    }, [dispatch, localeKey]);
    return useSelector((state) => state.localization[localeKey]);
};

export default useLocalization;
