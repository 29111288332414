/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import moo from 'moo';

import tagList from './tagList';

const toCaseInsensitiveRegex = (str) => {
    return str
        .split('')
        .map((char) => {
            return `[${char.toLowerCase()}${char.toUpperCase()}]`;
        })
        .join('');
};

/**
 * Builds a regex that supports the CLOSING of all possible tags - ex: {/TAG}
 */
const { tagCloseMatch, closeableTags } = (() => {
    const closeableTagsBuilder = [];
    for (const tag of tagList) {
        if (tag.requireClose) {
            closeableTagsBuilder.push(tag.tagName);
        }
    }
    // Nested tags that aren't in the tagList
    closeableTagsBuilder.push('LI', 'DD', 'DT');

    let tagCloseRegex = null;
    for (const tagName of closeableTagsBuilder) {
        if (tagCloseRegex === null) {
            tagCloseRegex = '\\{/(?:';
        } else {
            tagCloseRegex += '|';
        }
        tagCloseRegex += toCaseInsensitiveRegex(tagName);
    }
    tagCloseRegex += ')}';
    return {
        tagCloseMatch: new RegExp(tagCloseRegex),
        closeableTags: closeableTagsBuilder,
    };
})();

/**
 * This class uses an external library called "moo" that handles the tokenizing and lexer generation for us:
 * https://www.npmjs.com/package/moo
 */
const lexer = moo.states({
    main: {
        // Note: This should be equivalent to the Token regex in the Java code
        basicTagStart: {
            // Matches any tag or token between {}s - {b}, {i}, {ITEM_NAME}
            match: /\{[A-Za-z0-9_\-.]+}/,
            // Trims the start and end {}'s of the tag after we match one
            value: (x) => x.slice(1, -1),
        },
        tagOpenStart: {
            // Matches any tag that has the begining of tag but then needs extra information like color, icon name, etc  - {COLOR, {ICON, {PLACEHOLDER
            match: /\{[A-Za-z0-9_\-.]+/,
            push: 'tagOpen',
            // Trim the start { of the tag after we match one
            value: (x) => x.slice(1),
        },
        tagClose: {
            // Matches any tag that could possibly be closed (NOT TOKENS) - {/b}, {/i}, {/color}
            match: tagCloseMatch,
            // Trims that {/ of the tag after we match one
            value: (x) => x.slice(2, -1),
        },
        string: {
            // Matches any remaining text that does not contain tags - so the string inside tags and outside the tags
            match: /(?:[^{]+|\{)/,
            lineBreaks: true,
        },
    },
    tagOpen: {
        tagData: /(?:[ \t=]|[^}\s=]+)/,
        tagOpenEnd: { match: /}/, pop: 1 },
        tagOpenFail: { match: /[\n{"]/, lineBreaks: true, pop: 1 },
    },
});

class ParsedTag {
    constructor(tagName, tagData, contents = null, rawCloseTag = null) {
        this.tagName = tagName;
        this.upperTagName = tagName.toUpperCase();
        this.tagData = tagData;
        this.contents = contents;
        this.rawCloseTag = rawCloseTag;
    }
}

class State {
    constructor(parentState = null, tagName = null) {
        this.parentState = parentState;
        this.contents = [];
        this.tagName = tagName;
        this.upperTagName = tagName === null ? null : tagName.toUpperCase();
        this.tagData = [];
        this.tagOpenComplete = false;
    }

    isTag(tag) {
        return this.upperTagName === tag.toUpperCase();
    }
}

const inTag = (activeState, tag) => {
    let state = activeState;
    while (state) {
        if (state.isTag(tag)) {
            return true;
        }
        state = state.parentState;
    }
    return false;
};

const missingTagClose = (state) => {
    const parentState = state.parentState;
    parentState.contents.push(new ParsedTag(state.tagName, state.tagData));
    parentState.contents.push(...state.contents);
    return parentState;
};

const autoTagClose = (state) => {
    const parentState = state.parentState;
    parentState.contents.push(new ParsedTag(state.tagName, state.tagData));
    return parentState;
};

const closeTag = (activeState, tagToClose, rawCloseTag) => {
    let state = activeState;
    if (!inTag(state, tagToClose)) {
        state.contents.push(rawCloseTag);
    } else {
        while (state) {
            if (state.isTag(tagToClose)) {
                const tag = new ParsedTag(state.tagName, state.tagData, state.contents, rawCloseTag);
                state = state.parentState;
                state.contents.push(tag);
                break;
            } else {
                state = missingTagClose(state);
            }
        }
    }
    return state;
};

const failTagOpen = (state, failureData) => {
    const parentState = state.parentState;
    parentState.contents.push(`{${state.tagName}${state.tagData.join('')}${failureData}`);
    return parentState;
};

const processToken = (startingState, token) => {
    let currentState = startingState;
    switch (token.type) {
        case 'string':
            currentState.contents.push(token.value);
            break;
        case 'basicTagStart':
            currentState = new State(currentState, token.value);
            currentState.tagOpenComplete = true;
            if (!closeableTags.includes(currentState.upperTagName)) {
                currentState = autoTagClose(currentState);
            }
            break;
        case 'tagOpenStart':
            currentState = new State(currentState, token.value);
            break;
        case 'tagClose':
            currentState = closeTag(currentState, token.value, token.text);
            break;
        case 'tagData':
            currentState.tagData.push(token.value);
            break;
        case 'tagOpenEnd':
            currentState.tagOpenComplete = true;
            if (!closeableTags.includes(currentState.upperTagName)) {
                currentState = autoTagClose(currentState);
            }
            break;
        case 'tagOpenFail':
            currentState = failTagOpen(currentState, token.value);
            break;
        default:
            currentState.contents.push('ERROR');
    }
    return currentState;
};

export const parse = (input) => {
    const rootState = new State();
    let activeState = rootState;
    lexer.reset(input);
    for (const token of lexer) {
        activeState = processToken(activeState, token);
    }
    while (activeState !== rootState) {
        if (activeState.tagOpenComplete) {
            activeState = missingTagClose(activeState);
        } else {
            activeState = failTagOpen(activeState, '');
        }
    }
    lexer.reset();
    return rootState.contents;
};
