/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useEffect } from 'react';

import { useCurrentUser } from '~/components/user/UserContext';
import { getLogger } from '~/utils/logging';
import UntitledPage from '~components/page/UntitledPage';
import useRedirect from '~components/routing/useRedirect';
import Heading from '~components/text/Heading';
import LocalizedString from '~components/text/LocalizedString';
import useLocalizations from '~components/text/useLocalizations';

const REDIRECT_TIMEOUT_MS = 5000_000;

const TITLE_LOCALE_KEY = 'COMMON_UI.TITLE.ERROR_PAGE';
const BODY_LOCALE_KEY = 'COMMON_UI.MESSAGE.ERROR_PAGE';

const logger = getLogger(Symbol('ErrorPage'));

export const useLocalizationPrefetch = () => {
    useLocalizations([TITLE_LOCALE_KEY, BODY_LOCALE_KEY]);
};

const ErrorPage = () => {
    // TODO JAS webcrd_dev-34 Fix behavior when switching from an error page to another page before the redirect
    // TODO JAS webcrd_dev-34 Stylize this page
    const redirect = useRedirect();
    const userSettings = useCurrentUser();
    useEffect(() => {
        const timeout = setTimeout(() => {
            // TODO JAS webcrd_dev-34 Decide how we want to deal with errors. If we redirect to home (probably resulting in the login page
            // actually loading), we probably don't want a 1 second delayed redirect
            if (userSettings?.noRedirectOnError) {
                logger.warn('The "noRedirectOnError" flag was found in the current user (which should only be in the mock DB). Redirection disabled for errors.');
            } else {
                window.location.href = redirect.home().href;
            }
        }, REDIRECT_TIMEOUT_MS);
        return () => {
            clearTimeout(timeout);
        };
    });
    return (
        <UntitledPage>
            <div className="container container5xx">
                <Heading level={1}>
                    <LocalizedString localeKey={TITLE_LOCALE_KEY} />
                </Heading>
                <p>
                    <LocalizedString localeKey={BODY_LOCALE_KEY} />
                </p>
            </div>
        </UntitledPage>
    );
};

export default ErrorPage;
