/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';
import ReactIs from 'react-is';

const isFragment = (element) => {
    return ReactIs.typeOf(element) === ReactIs.Fragment;
};
const isElement = (element) => {
    return ReactIs.isElement(element);
};

const fragmentToString = (element) => {
    if (!isFragment(element)) {
        return element;
    }
    const children = element.props.children;
    if (!children) {
        return '';
    } else if (typeof children !== 'object') {
        return children;
    } else if (isFragment(children)) {
        return fragmentToString(children);
    } else if (isElement(children)) {
        return children;
    } else {
        let asString = '';
        for (const child of children) {
            const compressedChild = fragmentToString(child);
            if (typeof compressedChild === 'string') {
                asString += compressedChild;
            } else {
                return element;
            }
        }
        return asString;
    }
};

const joinChildren = (children) => {
    if (typeof children !== 'object') {
        return children;
    } else if (isFragment(children)) {
        return joinChildren(children.props.children);
    } else if (isElement(children)) {
        return children;
    }
    return joinChildrenArray(children);
};

const joinChildrenArray = (children) => {
    const newChildren = [];
    let stringChild = '';
    for (const child of children) {
        const compressedChild = fragmentToString(child);
        if (typeof compressedChild === 'string') {
            stringChild += compressedChild;
        } else {
            if (stringChild !== '') {
                newChildren.push(stringChild);
                stringChild = '';
            }
            if (Array.isArray(child)) {
                newChildren.push(...joinChildren(child));
            } else if (isFragment(child)) {
                newChildren.push(joinFragments(child));
            } else {
                newChildren.push(child);
            }
        }
    }
    if (stringChild !== '') {
        newChildren.push(stringChild);
    }
    return newChildren;
};

export const joinFragments = (element) => {
    if (isFragment(element)) {
        const children = element.props.children;
        if (children) {
            const newChildren = joinChildren(children);
            return <Fragment key={element.key}>{newChildren}</Fragment>;
        }
    }
    return element;
};
