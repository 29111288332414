/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

export const AlertType = Object.freeze({
    MUTED: 'muted',
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    INFORMATION: 'info',
    USER_ERROR: 'userError',
    SYSTEM_ERROR: 'systemError',
});