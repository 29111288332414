/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { getLogger } from '~utils/logging';

const logger = getLogger(Symbol('instance'));

const ORIGIN = window.location.origin;

const SCRIPT_PATH = (() => {
    if (!document || !document.currentScript) {
        // This should only ever happen when running jest
        return `/TEST_INSTANCE${__ROLE_PATH__}`;
    }
    const src = document.currentScript.src;
    if (src.startsWith(ORIGIN)) {
        return src.substring(ORIGIN.length);
    } else {
        return src;
    }
})();

const PATH_PREFIX = SCRIPT_PATH.substring(0, SCRIPT_PATH.indexOf(__ROLE_PATH__)) + '/';

// Don't allow any control characters in URLs
// eslint-disable-next-line no-control-regex
const INVALID_CHARACTERS = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/;
const ALLOWED_PROTOCOLS = ['mailto:', 'tel:', 'http:', 'https:'];
const LEADING_SLASH = /^\/+/g;

const fixInternalURL = (url, urlObj) => {
    let fixedURL;
    if (url === '#') {
        fixedURL = '#';
    } else if (url.startsWith('#')) {
        fixedURL = urlObj.hash;
    } else {
        fixedURL = `${urlObj.pathname}${urlObj.search}${urlObj.hash}`;
        if (!fixedURL.startsWith(PATH_PREFIX)) {
            fixedURL = `${PATH_PREFIX}${fixedURL.replace(LEADING_SLASH, '')}`;
        }
    }
    return fixedURL;
};

export const fixURL = (url) => {
    if (typeof url !== 'string') {
        // If it's not even a string, a developer made a mistake in checking input values that they should fix
        // Any user-supplied input should at least be type checked before calling fixURL
        throw new Error('fixURL expects strings');
    }

    // Strip out invalid characters, and de-dupe extra leading slashes
    const sanitizedURL = url.replace(INVALID_CHARACTERS, '').replace(LEADING_SLASH, '/');
    let fixedURL;
    try {
        const urlObj = new URL(sanitizedURL, ORIGIN);
        if (urlObj.origin === ORIGIN && !sanitizedURL.startsWith(urlObj.origin)) {
            // If the origin matches, and the passed in URL didn't include the origin explicitly, it's an internal URL
            // If the origin was included explicitly, we want to treat it like an external URL
            fixedURL = fixInternalURL(sanitizedURL, urlObj);
        } else if (ALLOWED_PROTOCOLS.includes(urlObj.protocol)) {
            // If the external URL has a "safe" protocol, use the href straight from the URL object (it will be sanitized and escaped appropriately)
            fixedURL = urlObj.href;
        } else {
            // It's not an internal URL, and the protocol isn't supported - send them somewhere safe instead
            logger.debug('A URL passed to fixURL had an unsupported protocol', url);
            fixedURL = 'about:blank';
        }
    } catch (e) {
        // The URL was malformed and the built-in URL object couldn't parse it - send them somewhere safe instead
        logger.error('Error parsing URL passed to fixURL', url);
        return 'about:blank';
    }
    return fixedURL;
};
