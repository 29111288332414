/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { automationIDPropTypes, getExtraAutomationID } from '~/components/id/AutomationID';
import MenuContainer from '~components/a11y/MenuContainer';

const HeaderToolbarDropdown = forwardRef(({ automationID, children }, ref) => {
    const { id } = getExtraAutomationID(automationID, 'dropdown');
    return (
        <MenuContainer ref={ref} id={id} className="toolbar-dropdown">
            {children}
        </MenuContainer>
    );
});
HeaderToolbarDropdown.displayName = 'HeaderToolbarDropdown';

HeaderToolbarDropdown.propTypes = {
    automationID: automationIDPropTypes,
    children: PropTypes.node.isRequired,
};

export default HeaderToolbarDropdown;
