/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { getLogger } from '~utils/logging';

const logger = getLogger(Symbol('Components:a11y:useScrollIntoView'));

const SCROLL_MARGIN_TOP_PADDING = 8;

const CSS_SCROLL_MARGIN_SUPPORTED = (() => {
    // This is expected to return false only for Safari < 14.1 and iOS Safari < 14.6 (and older browsers we don't support)
    // Once we stop supporting old Safari, we should remove this check and the associated manual scroll workaround
    const scrollMarginProperty = 'scroll-margin-top';
    const testValue = '5px';
    const d = document.createElement('div');
    d.style[scrollMarginProperty] = testValue;
    return d.style[scrollMarginProperty] === testValue;
})();

let manualScrollMarginTop = 0;
export const setScrollMarginTop = (newTop) => {
    const calculatedTop = SCROLL_MARGIN_TOP_PADDING + newTop;
    if (logger.isDebugEnabled()) {
        logger.debug(`setScrollMarginTop(${newTop}): ${calculatedTop}`);
    }
    if (CSS_SCROLL_MARGIN_SUPPORTED) {
        logger.debug('applying scroll-margin-top via css');
        document.documentElement.style.setProperty('--scroll-margin-top', `${calculatedTop}px`);
    } else {
        logger.debug('saving scroll-margin-top for later use to apply via JS');
        manualScrollMarginTop = calculatedTop;
    }
};

const useScrollIntoView = (ref) => {
    return () => {
        const elem = ref.current;
        if (!elem) {
            return;
        }
        if (manualScrollMarginTop) {
            const top = elem.offsetTop - manualScrollMarginTop;
            const scrollTop = document.documentElement.scrollTop;

            // Note: This only works if you need to scroll UP to see the element. If we get into situations where
            // it would be realistic to need to scroll down, then someone will need to implement that behavior.
            if (top < scrollTop) {
                if (logger.isDebugEnabled()) {
                    logger.debug(`manually applying scroll-margin-top of ${manualScrollMarginTop}`);
                }
                window.scroll(0, top);
            } else {
                if (logger.isDebugEnabled()) {
                    logger.debug(`top of element already low enough; top[${top}] scrollTop[${scrollTop}]`);
                }
            }
        } else {
            elem.scrollIntoView();
        }
    };
};

export default useScrollIntoView;
