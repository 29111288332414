/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import FocusTrap from '~components/a11y/FocusTrap';
import { useMenuContext } from '~components/a11y/MenuContext';

const MenuContainer = forwardRef(({ id, className, style, children, Tag }, ref) => {
    const { isActive, allowFocus, collapseMenu } = useMenuContext();

    // Note: Make sure {children} are ALWAYS included, even when the menu is not active, because the children might
    // include things that appear outside the menu, like modals.

    if (isActive && allowFocus) {
        return (
            <Tag id={id} ref={ref} className={className} style={style}>
                <FocusTrap onFocusLost={collapseMenu}>
                    {children}
                </FocusTrap>
            </Tag>
        );
    }

    return (
        <Tag id={id} ref={ref} className={className} style={style}>
            {children}
        </Tag>
    );
});

MenuContainer.displayName = 'MenuContainer';

MenuContainer.defaultProps = {
    Tag: 'ul',
};

MenuContainer.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    Tag: PropTypes.oneOf(['ul', 'div']),
};

export default MenuContainer;
