/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import bold from './tags/bold';
import br from './tags/br';
import center from './tags/center';
import color from './tags/color';
import dictionaryList from './tags/dictionaryList';
import fontFamily from './tags/fontFamily';
import fontSize from './tags/fontSize';
import h3 from './tags/h3';
import h4 from './tags/h4';
import hideOnMobile from './tags/hideOnMobile';
import highlight from './tags/highlight';
import hpad from './tags/hpad';
import icon from './tags/icon';
import image from './tags/image';
import italics from './tags/italics';
import left from './tags/left';
import leftIndent from './tags/leftIndent';
import link from './tags/link';
import middot from './tags/middot';
import mobileOnly from './tags/mobileOnly';
import nbsp from './tags/nbsp';
import orderedList from './tags/orderedList';
import paragraph from './tags/paragraph';
import placeholder from './tags/placeholder';
import popup from './tags/popup';
import right from './tags/right';
import smartImage from './tags/smartImage';
import subscript from './tags/subscript';
import superscript from './tags/superscript';
import underline from './tags/underline';
import unorderedList from './tags/unorderedList';
import vpad from './tags/vpad';

const tagList = [
    br,
    nbsp,
    middot,
    bold,
    italics,
    underline,
    paragraph,
    fontSize,
    fontFamily,
    color,
    hpad,
    leftIndent,
    vpad,
    left,
    center,
    right,
    superscript,
    subscript,
    icon,
    image,
    smartImage,
    link,
    popup,
    orderedList,
    unorderedList,
    dictionaryList,
    highlight,
    h3,
    h4,

    placeholder,
    hideOnMobile,
    mobileOnly,
    // Note: Tokens are handled separately
];

export default tagList;

export const tagMap = tagList.reduce((acc, value) => {
    acc.set(value.tagName, value);
    return acc;
}, new Map());
