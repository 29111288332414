/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

/**
 * Freezes an object and all children recursively.
 *
 * WARNING: Only use this on simple objects that you define directly or can fully predict the entire scope of.
 * Use on self referential objects will fail due to a stack overflow, and freezing DOM elements, React components, and other
 * complex object types that are controlled by libraries we use could break the libraries that define those objects.
 *
 * @param {T} object
 * @returns {T}
 * @template T
 */
const deepFreeze = (object) => {
    for (const key of Object.keys(object)) {
        const value = object[key];
        if (value && typeof value === 'object' || Array.isArray(value)) {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
};

export default deepFreeze;