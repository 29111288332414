/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Blocks text as a paragraph (shows on separate lines from the text before/after, sometimes with additional styling depending on context)
 * {p}Text to be its own paragraph{/p}
 */
export default new SimpleTag(
    'p',
    (key, contents) => {
        return <p key={key}>{contents}</p>;
    },
    (contents) => `\n${contents}\n`
);
