/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

// path to the server endpoint to Account Email Options
const path = 'emailOptions';

// use the api to request the data
export const get = async () => {
    return api.get(path);
};

// use the api to send an update to the data
export const update = async (changes) => {
    return api.patch(path, changes);
};