/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { Fragment } from 'react';

export const renderRawTag = (parsedTag, keyGen) => {
    return <Fragment key={keyGen.next}>{`{${parsedTag.tagName}${parsedTag.tagData.join('')}}`}</Fragment>;
};

export const renderRawTagAsText = (parsedTag) => {
    return `{${parsedTag.tagName}${parsedTag.tagData.join('')}}`;
};

export const renderContentsAndClose = (parsedTag, keyGen, renderContents) => {
    return (
        <Fragment key={keyGen.next}>
            {renderContents(parsedTag.contents)}
            {parsedTag.rawCloseTag}
        </Fragment>
    );
};

export const renderContentsAsTextAndClose = (parsedTag, renderContents) => {
    return `${renderContents(parsedTag.contents)}${parsedTag.rawCloseTag}`;
};

export const render = (parsedTag, keyGen, renderContents) => {
    if (parsedTag.contents === null) {
        return renderRawTag(parsedTag, keyGen);
    } else {
        return (
            <Fragment key={keyGen.next}>
                {renderRawTag(parsedTag, keyGen)}
                {renderContentsAndClose(parsedTag, keyGen, renderContents)}
            </Fragment>
        );
    }
};

export const renderAsText = (parsedTag, renderContents) => {
    if (parsedTag.contents === null) {
        return renderRawTagAsText(parsedTag);
    } else {
        return `${renderRawTagAsText(parsedTag)}${renderContentsAsTextAndClose(parsedTag, renderContents)}`;
    }
};
