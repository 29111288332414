/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Bold tags
 * {b}Text to be bold{/b}
 */
export default new SimpleTag(
    'b',
    (key, contents) => {
        return <strong key={key}>{contents}</strong>;
    },
    (contents) => contents
);
