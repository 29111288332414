/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import * as rawTagRenderer from './rawTagRenderer';
import SimpleTag from './simpleTag';
import Tag from './tag';

const term = new SimpleTag(
    'dt',
    (key, contents) => {
        return <dt key={key}>{contents}</dt>;
    },
    (contents) => `${contents}`
);

const definition = new SimpleTag(
    'dd',
    (key, contents) => {
        return <dd key={key}>{contents}</dd>;
    },
    (contents) => `${contents}`
);

const getTag = (content) => {
    return content.upperTagName === 'DD' ? definition : term;
};

const isValidTag = (upperTagName, lastTag) => {
    if (upperTagName !== 'DT' && upperTagName !== 'DD') {
        // Only DT and DD tags allowed
        return false;
    }
    if (upperTagName === lastTag) {
        // Can't have the same tag twice in a row
        return false;
    }
    if (lastTag === null && upperTagName !== 'DT') {
        // The first tag needs to be DT
        return false;
    }
    return true;
};

const isValidDictionaryList = (contents) => {
    let lastTag = null;
    for (const content of contents) {
        if (typeof content === 'string') {
            // Ignore whitespace between the tags, but don't allow non-whitespace
            if (content.trim() !== '') {
                return false;
            }
        } else {
            const upperTagName = content.upperTagName;
            if (!isValidTag(upperTagName, lastTag)) {
                return false;
            }
            lastTag = upperTagName;
        }
    }
    // We need to end with a DD
    return lastTag === 'DD';
};

const mapTags = (contents, render) => {
    return contents.filter((content) => typeof content !== 'string').map((content) => render(content, getTag(content)));
};

/**
 * Dictionary lists
 * {dl}{dt}Term 1{/dt}{dd}Definition 1{/dd}{dt}Term 2{/dt}{dd}Definition 2{/dd}...{/dl}
 */
class DictionaryList extends Tag {
    constructor() {
        super('dl');
    }

    renderTag({ parsedTag, keyGen, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidDictionaryList(contents)) {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        const renderedContents = mapTags(contents, (content, tag) =>
            tag.render({ parsedTag: content, keyGen, renderContents })
        );
        return <dl key={keyGen.next}>{renderedContents}</dl>;
    }

    renderTagAsText({ parsedTag, renderContents }) {
        const contents = parsedTag.contents;
        if (!isValidDictionaryList(contents)) {
            return rawTagRenderer.renderAsText(parsedTag, renderContents);
        }
        const renderedContents = mapTags(contents, (content, tag) =>
            tag.renderAsText({ parsedTag: content, renderContents })
        );
        return `\n${renderedContents.join('\n')}\n`;
    }
}

export default new DictionaryList();
