/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

const path = 'cart';

export const get = async () => {
    return api.get(path);
};

export const setID = async (id) => {
    return api.patch(path, { id });
};

export const upload = async ({ files, paperSelectionFilter, jobTicketTemplate }) => {
    return api.post(`${path}/upload`, { files, paperSelectionFilter, jobTicketTemplate }, { isMultiPart: true });
};

export const specialOrder = async () => {
    return api.post(`${path}/specialOrder`);
};

export const addItemFromCatalog = async (item, quantity, isBackorderConfirmed) => {
    return api.post(`${path}/addItemFromCatalog`, { itemID: item.id, quantity, isBackorderConfirmed });
};

export const addRecipient = async (addressID, shipMethodID, shipperAccountNumber, instructions, quantities) => {
    return api.post(`${path}/addRecipient`, { addressID, shipMethodID, shipperAccountNumber, instructions, quantities });
};

export const addDistributionList = async (listID, shipMethodID, shipperAccountNumber, instructions) => {
    return api.post(`${path}/addDistributionList`, { listID, shipMethodID, shipperAccountNumber, instructions });
};

export const splitAccountCodeType = async (accountCodeTypeID) => {
    return api.post(`${path}/splitAccountCodeType`, { accountCodeTypeID: accountCodeTypeID });
};

export const submitOrder = async () => {
    return api.post(`${path}/submitOrder`);
};

// used to change current cart to saved
export const save = async () => {
    return api.patch(`${path}/save`);
};
