/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createRoot } from 'react-dom/client';

import AppWrapper from '~components/routing/AppWrapper';

import App from './App';
import store from './store';

// Initialize the rendering of the Root element for the Public role
const root = createRoot(document.getElementById('root'));
root.render(<AppWrapper store={store}><App /></AppWrapper>);
