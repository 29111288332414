/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import * as rawTagRenderer from './rawTagRenderer';

export default class Tag {
    constructor(tagName) {
        this.upperTagName = tagName.toUpperCase();
    }

    get requireClose() {
        return true;
    }

    get tagName() {
        return this.upperTagName;
    }

    render({ parsedTag, keyGen, renderContents }) {
        if (parsedTag.contents === null) {
            return rawTagRenderer.render(parsedTag, keyGen, renderContents);
        }
        return this.renderTag({ parsedTag, keyGen, renderContents });
    }

    renderAsText({ parsedTag, renderContents }) {
        if (parsedTag.contents === null) {
            return rawTagRenderer.renderAsText(parsedTag, renderContents);
        }
        return this.renderTagAsText({ parsedTag, renderContents });
    }

    renderTag() {
        throw new Error('renderTag was not implemented');
    }

    renderTagAsText() {
        throw new Error('renderTagAsText was not implemented');
    }
}
