/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { merge } from '~common-store/helpers/mergeUpdates';
import deepFreeze from '~utils/objects/deepFreeze';

import { printOptionsFullReplacements } from './printOptions';

/**
 * Order fields that shouldn't be partially updated when doing a merge, because we never expect partial updates.
 * Example: Order Document -> Print Options -> Output Type. We don't want to have to bother with explicit false values for isColor/isWide,
 *          but if we used a standard merge w/o flagging it as a full replacement, we'd have to (otherwise, replacing Color
 *          with B/W would fail to clear the isColor flag).
 * Another Example: Site is an object that has a SiteID and SiteName. We would never want to update just the SiteID and not the
 *          SiteName. This object when being updated should be treated as all-or-nothing and fully replaced when an updates comes in.
 */
const orderFullReplacements = deepFreeze({
    site: true,
    status: true,
    orderDocuments: {
        sourcePages: true,
        printOptions: printOptionsFullReplacements,
    },
    serviceLevel: true,
    recipients: {
        shipMethod: true,
    },
    pricing: true,
    billing: {
        accountCodes: {
            maskValidity: true,
        },
    },
});

/**
 * The request for price recalculations are made at the single update level. So when you want to updateRecipient for
 * shipping instructions, we pass along a false so we know not to update the price for those instructions, and when we
 * want to updateRecipient for the ship method we pass along a true.
 * Order updates are batched together. So both a ship method and instructions update can be combined into one order update
 * API call if they happen quick enough. So we could have conflicting requiresPriceRecalculate bools coming in with our
 * individual updates. We don't want requiresPriceRecalculate to be a true for ship method and then reverted to false when
 * ship instructions comes in. So if we already have requiresPriceRecalculate as true, that will remain our value, regardless of
 * any subsequent updates. If the current requiresPriceRecalculate is false (draftOrder.requiresPriceRecalculate), only
 * then do we fallback to the requiresPriceRecalculate of the changes coming in (orderChanges.requiresPriceRecalculate).
 */
const mergeOrderChanges = (draftOrder, orderChanges) => {
    const requiresPriceRecalculate = draftOrder.requiresPriceRecalculate || orderChanges.requiresPriceRecalculate;

    merge(draftOrder, orderChanges, orderFullReplacements);

    // After the merge, set the current requires back to the correct value because we don't always want to just take whatever
    // orderChanges had as a value during the merge() call.
    if (requiresPriceRecalculate !== undefined) {
        draftOrder.requiresPriceRecalculate = requiresPriceRecalculate;
    }
};

export { mergeOrderChanges };
