/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Hides text on mobile devices
 * {hideOnMobile}Text for large screens only{/hideOnMobile}
 */
export default new SimpleTag(
    'hideOnMobile',
    (key, contents) => {
        return (
            <span key={key} className="hidden-xs-down">
                {contents}
            </span>
        );
    },
    (contents) => `${contents}`
);
