/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

const path = 'active';

// The API endpoints for /api/ordering/books/active

/**
 * Get the active book's details
 *
 * @returns {object} book details in JSON
 */
export const get = async () => {
    return api.get(path);
};

/**
 * Add items from an upload to the active book
 * NOTE: There is no ability to add items to non-active books
 */
export const upload = async ({ files }) => {
    return api.post(`${path}/upload`, { files }, { isMultiPart: true });
};

/**
 * Add the item from the catalog to the active book
 * NOTE: There is no ability to add items to non-active books
 *
 * @param {object} item - To get the ID of the item
 * @returns {object} a success or failure
 */
export const addItemFromCatalog = async (item) => {
    return api.post(`${path}/addItemFromCatalog`, { itemID: item.id });
};


/**
 * Remove an item from the active book
 *
 * @param {object} bookDocumentID - The item's bookDocumentID
 * @returns {object} a success or failure
 */
export const removeItemFromBook = async (bookDocumentID) => {
    return api.post(`${path}/removeItem`, { bookDocumentID: bookDocumentID });
};

/**
 * Rearranges a book
 *
 * @param {object} bookDocumentID - The item's bookDocumentID
 * @param {object} newPosition - The item's new position
 * @returns {object} a success or failure
 */
export const rearrangeInBook = async (bookDocumentID, newPosition) => {
    return api.post(`${path}/changePositionToAfter`, {
        bookDocumentID: bookDocumentID,
        newPosition: newPosition,
    });
};


/**
 * Assemble the active book
 *
 * @returns {object} a success or failure
 */
export const assembleBook = async () => {
    return api.post(`${path}/assemble`);
};


/**
 * Assemble and saves the active book
 *
 * @returns {object} a success or failure
 */
export const assembleAndOrderBook = async () => {
    return api.post(`${path}/assembleAndOrder`);
};