/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */


import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';

import getEnhancedComponentName from '~components/getEnhancedComponentName';

/**
 * Wraps a component in functionality that will disable the component while the action is in progress.
 *
 * THIS SHOULD NOT BE USED DIRECTLY.
 * See ContainerWithAction ContainerWithWaitAction exports.
 */
const withWaitForAction = (WrappedComponent) => {
    const EnhancedComponent = ({
        action,
        ...buttonProps
    }) => {
        // Pull the children out of the props
        const { children } = buttonProps;

        const [inProgress, setInProgress] = useState(false);

        const buttonAction = useMemo(() => {
            if (!action || inProgress) {
                return undefined;
            }
            return async () => {
                // The progress state will be used below to determine if the component is disabled
                setInProgress(true);
                await action();
                setInProgress(false);
            };
        }, [action, inProgress, setInProgress]);


        // We have our original action that is expanded on with a progress state, now just stick it into
        // the wrapped component as an action (and also tie disabled to the state)

        // WrappedComponent right now is a Button but it could be other components
        // that also support the action and disabled props
        return (
            <WrappedComponent action={buttonAction} disabled={inProgress} {...buttonProps}>
                {inProgress && <div className="save-state--saving spinner-border spinner-border-sm save-state--current" role="status"/>}
                {!inProgress && children}
            </WrappedComponent>
        );
    };

    EnhancedComponent.propTypes = {
        action: PropTypes.func,
    };

    EnhancedComponent.displayName = getEnhancedComponentName('withWaitForAction', WrappedComponent);

    return EnhancedComponent;
};

export default withWaitForAction;