/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import useCallbackRef from '~utils/hooks/useCallbackRef';
import { getLogger } from '~utils/logging';

import { focusOnFirstChild, focusOnLastChild } from './focusOnChild';
import isClickInside, { CLICK_LISTENER_OPTIONS } from './isClickInside';
import { useMenuContext } from './MenuContext';

const logger = getLogger(Symbol('Components:a11y:FocusTrap'));

const initializeFocusTrap = ({ collapseMenu, topFocusRef, bottomFocusRef, onFocusLost, previousFocusRef, TrapTag }) => {

    const trapParent = topFocusRef.parentNode;
    if (trapParent.firstElementChild !== topFocusRef || trapParent.lastElementChild !== bottomFocusRef) {
        logger.error('FocusTrap was not the first and only child of an HTML element - this may result in unexpected behavior');
    }
    previousFocusRef.current = document.activeElement;

    // Set the focus inside the trap, but before any focusable elements, so "Tab" goes to the first focusable element,
    // and "Shift-Tab" goes to the last focusable element.
    const focusWasVisible = document.activeElement?.matches(':focus-visible');
    if (focusWasVisible) {
        focusOnFirstChild(trapParent);
    } else {
        const initialFocusElem = document.createElement(TrapTag);
        trapParent.insertBefore(initialFocusElem, topFocusRef.nextSibling);
        initialFocusElem.tabIndex = '0';
        initialFocusElem.focus();
        trapParent.removeChild(initialFocusElem);
    }

    const topFocusListener = () => {
        focusOnLastChild(trapParent);
    };
    const bottomFocusListener = () => {
        focusOnFirstChild(trapParent);
    };

    topFocusRef.addEventListener('focus', topFocusListener);
    bottomFocusRef.addEventListener('focus', bottomFocusListener);

    // If while using using keys only, they get stuck in a focus trap of a expandable menu like Cart
    // allow them to hit Escape to break out of the focus
    const keyListener = (e) => {
        const key = e.key;
        if (key === 'Escape') {
            collapseMenu();
        }
    };

    let clickListener;
    if (onFocusLost) {
        clickListener = (event) => {
            if (!isClickInside({ event, parent: trapParent })) {
                logger.info('A user clicked outside the FocusTrap');
                onFocusLost();
            }
        };
        document.addEventListener('click', clickListener, CLICK_LISTENER_OPTIONS);
    }

    document.addEventListener('keydown', keyListener);

    return () => {
        topFocusRef.removeEventListener('focus', topFocusListener);
        bottomFocusRef.removeEventListener('focus', bottomFocusListener);
        document.removeEventListener('keydown', keyListener);
        if (clickListener) {
            document.removeEventListener('click', clickListener, CLICK_LISTENER_OPTIONS);
        }
        previousFocusRef.current && previousFocusRef.current.focus();
    };
};


/**
 * Traps focus inside the parent node.
 *
 * Why do we have our own FocusTrap, instead of using focus-trap-react?
 * Because when we tried to use focus-trap-react, we found it to be overzealous.
 * With focus-trap-react, if we show Toasts while focus is trapped, those toasts can't be closed,
 * because focus-trap-react prevents the click from happening.
 *
 * Note: FocusTrap is expected to be the first and only child of an HTML element.
 *       If it isn't, the focus trapping will not work as expected.
 */
const FocusTrap = ({ children, onFocusLost, TrapTag, ready }) => {
    const [topFocusRef, setTopFocusRef] = useCallbackRef();
    const [bottomFocusRef, setBottomFocusRef] = useCallbackRef();
    const previousFocusRef = useRef();

    // If there is no menu, fallback to {} so it wont deconstruct a null and throw error
    const { collapseMenu } = useMenuContext() || {};

    useEffect(() => {
        if (!topFocusRef || !bottomFocusRef || !ready) {
            // The trap isn't fully setup yet
            return undefined;
        }
        return initializeFocusTrap({ collapseMenu, topFocusRef, bottomFocusRef, onFocusLost, previousFocusRef, TrapTag });
    }, [topFocusRef, bottomFocusRef, onFocusLost, previousFocusRef, TrapTag, ready, collapseMenu]);

    return (
        <>
            {/* The interactivity of these elements is handled by `initializeFocusTrap` */}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <TrapTag ref={setTopFocusRef} tabIndex="0" data-focus-trap="top" role="none" />
            {children}
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <TrapTag ref={setBottomFocusRef} tabIndex="0" data-focus-trap="bottom" role="none" />
        </>
    );
};

FocusTrap.defaultProps = {
    TrapTag: 'div',
    ready: true,
};

FocusTrap.propTypes = {
    children: PropTypes.node,
    onFocusLost: PropTypes.func,
    TrapTag: PropTypes.oneOf(['li', 'div']),
    ready: PropTypes.bool,
};

export default FocusTrap;