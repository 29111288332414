/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import PlaceholderText from '../skeleton/PlaceholderText';

import FormattedString from './FormattedString';
import { formattedStringParamtersPropType } from './rsaMessageFormat/propTypes';
import useLocalization from './useLocalization';

const LocalizedString = ({ localeKey, parameters }) => {
    const localization = useLocalization(localeKey);
    if (typeof localization !== 'string') {
        return <PlaceholderText placeholderFor={localeKey} />;
    } else {
        return <FormattedString pattern={localization} parameters={parameters} source={localeKey} />;
    }
};

LocalizedString.propTypes = {
    localeKey: PropTypes.string.isRequired,
    parameters: formattedStringParamtersPropType,
};

export default LocalizedString;
