/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import BaseWidget from './BaseWidget';

const LinksWidget = ({ ...widgetProps }) => {
    return <BaseWidget type="links" {...widgetProps} />;
};

export default LinksWidget;
