/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Changes the font size of text
 * {fontSize=large}Text to be made large{/fontSize}
 * {fontSize=20px}Text to have a size of 20px{/fontSize}
 * {fontSize=10pt}Text to have a size of 10pt{/fontSize}
 */
export default new SingleAttributeTag(
    'fontSize',
    validAttributePattern,
    (key, attributeValue, contents) => {
        return (
            <span key={key} style={{ fontSize: attributeValue }}>
                {contents}
            </span>
        );
    },
    (_attributeValue /* unused for text formatting */, contents) => contents
);
