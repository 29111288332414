/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import api from './api';

// The API endpoints for /api/ordering/books/{book ID}

/**
 * Add the item from the catalog to the active book
 * NOTE: There is no ability to add items to non-active books
 *
 * @param {object} bookID - ID of the book
 * @returns {object} a success or failure
 */
export const renameBook = async (bookID, name) => {
    return api.patch(`${bookID}`, { newName: name });
};

/**
 * Delete a book
 *
 * @param {object} bookID - ID of the book
 * @returns {object} a success or failure
 */
export const deleteBook = async (bookID) => {
    return api.delete(`${bookID}`);
};

/**
 * Set the active book
 * By default clears the active book by setting it to null
 *
 * @returns {object} a success or failure
 */
export const setActiveBook = async (bookID = null) => {
    return api.post(`active`, { bookID: bookID });
};

/**
 * Get the versions of a book
 *
 * @returns {object} a success or failure
 */
export const getBookVersions = async (bookID = null) => {
    return api.get(`${bookID}/versions`);
};
