/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useSetRouteHelpKey } from '~components/header/help/HelpContext';
import SmartImageNoAlt from '~components/images/SmartImageNoAlt';
import UntitledPage from '~components/page/UntitledPage';
import Heading from '~components/text/Heading';
import LocalizedString from '~components/text/LocalizedString';
import useLocalizations from '~components/text/useLocalizations';

import usePathName from '../usePathName';

const TITLE_LOCALE_KEY = 'COMMON_UI.TITLE.PAGE_NOT_FOUND';
const BODY_LOCALE_KEY = 'COMMON_UI.MESSAGE.PAGE_NOT_FOUND';

export const useLocalizationPrefetch = () => {
    useLocalizations([TITLE_LOCALE_KEY, BODY_LOCALE_KEY]);
};

const PageNotFound = () => {
    const pathName = usePathName();
    useSetRouteHelpKey('PageNotFound');

    // TODO JAS webcrd_dev-34 Stylize this page
    return (
        <UntitledPage>
            <div className="container container404" data-path-name={pathName}>
                <SmartImageNoAlt name="404" />
                <Heading level={1}>
                    <LocalizedString localeKey={TITLE_LOCALE_KEY} />
                </Heading>
                <p>
                    <LocalizedString localeKey={BODY_LOCALE_KEY} />
                </p>
            </div>
        </UntitledPage>
    );
};

export default PageNotFound;
