/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import FeatherIcon, { FeatherIconButton } from './FeatherIcon';
import MaterialIcon, { MaterialIconButton } from './MaterialIcon';

const libraryMap = new Map();
libraryMap.set('material', MaterialIcon);
libraryMap.set('feather', FeatherIcon);

const buttonLibraryMap = new Map();
buttonLibraryMap.set('material', MaterialIconButton);
buttonLibraryMap.set('feather', FeatherIconButton);

export default Object.freeze([...libraryMap.keys()]);

export const lookupComponent = (library) => {
    return library && libraryMap.get(library.toLowerCase()) || FeatherIcon;
};

export const lookupButtonComponent = (library) => {
    return library && buttonLibraryMap.get(library.toLowerCase()) || FeatherIconButton;
};

