/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { notFound as orderNotFound } from '~/common-store/helpers/notFound';
import APIError from '~api/APIError';
import { get as fetchOrder, update as sendOrderUpdate } from '~api/ordering/orders';
import { get as fetchCart, setID as sendCartID } from '~api/ordering/orders/cart';
import { createConsolidatedUpdateAsyncThunk, createSpamProtectedAsyncThunk } from '~common-store/helpers/spamProtection';
import { getLogger } from '~utils/logging';

import { mergeOrderChanges } from '../helpers/orders';
import { getShipDateOptions } from '../slices/shipDateOptions';
import { getShipMethods } from '../slices/shipMethods';

const logger = getLogger(Symbol('Store:Actions:Order'));

// Update orders at most once every second (plus the time it takes for the last request to process), to avoid spamming the server
const ORDER_REFRESH_DELAY = 1000;

const protectedGetCart = createSpamProtectedAsyncThunk(
    'orders/getCart',
    async (_arg, { rejectWithValue }) => {
        try {
            const response = await fetchCart();
            const pendingChanges = response ? getPendingOrderChanges(response.id) : null;
            return {
                id: response ? response.id : null,
                order: response,
                pendingChanges,
            };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }, {
        delay: ORDER_REFRESH_DELAY,
    }
);

export const getCart = ({ callback } = {}) => protectedGetCart({ id: 'cart', callback });

export const clearCart = createAsyncThunk(
    'orders/clearCart',
    async (_arg, { rejectWithValue }) => {
        try {
            await sendCartID(null);
            // No data needed to process a successful clearCart Thunk
            return undefined;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getOrder = createSpamProtectedAsyncThunk(
    'orders/get',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await fetchOrder(id);
            const pendingChanges = getPendingOrderChanges(id);
            return {
                id,
                order: response,
                pendingChanges,
            };
        } catch (error) {
            // The error should always be an APIError type... but we support other thrown errors to be a little more graceful if there is a bug
            const errorDetails = {};

            if (typeof error === 'string') {
                errorDetails.message = error;
            } else {
                errorDetails.message = error.message;
            }

            if (error instanceof APIError) {
                errorDetails.apiErrorType = error.type;

                // Not needed by the code, but can be useful for debugging errors
                errorDetails.requestID = error.requestID;
            }
            return rejectWithValue(errorDetails);
        }
    }, {
        delay: ORDER_REFRESH_DELAY,
    }
);

/**
 * Here's an an entire broad timeline of events for performing an update. Let's say we have a page with three checkboxes and an input.
 * We are going to click checkbox X. Our state for these 4 inputs would be an object like this:
 * {
 *      x: false,
 *      y: false,
 *      z: false,
 *      number: 100
 * }
 * When we click checkbox X we dispatch our updateOrder function with some parameters:
 *
 *      dispatch(updateOrder({ id: 'SETTINGS', changes, callback }));
 *
 * Where 'id' is the ID of which thing we want to update if we had multiple things available. Like multiple orders. However, these
 * checkboxes are just a single centralized batch of settings so we choose some static ID.
 *
 * The 'callback' is some function you want to call after there is a response from the server. Because this is all asynchronous
 * there is no telling when the response will return so callbacks are a way to guarantee something external from this slice
 * will run. These callbacks will also be queued up so if you have multiple changes batched together (more on that later), once the response
 * for those batch changes are returned from the server the callbacks for each of those updates will be called in the order they were queued
 *
 * The 'changes' will be an object containing the changes we are making:
 * {
 *      x: true
 * }
 *
 * Notice how there is no y in there. We only clicked checkbox X.
 *
 * The first thing we hit is the 'onQueued' function below. This is going to take our change and update our local state.
 * This is BEFORE we even send a request to the server. You see, checkbox X is linked to the state. It's something called a
 * "two-way binding" in react. The value of the checkbox is the value of the state and the onClick changes the state. So if the state remains
 * false, the checkbox will remain unchecked even though you just clicked it. And we don't want to have to wait for a response
 * from the server since it will remain unchecked until then and you then you would have a delayed response with your UI.
 *
 * So the moment we queue this change we just assume the server will be a success and that it will be updated on the server side.
 * We dispatch a addPendingOrderUpdate function that will do the merge of our changes. Our dispatch is only going to be dealt
 * with on the client-side, so it's handled in the "regular" reducers below. Below we see how this dispatch is handled:
 *
 *       addPendingOrderUpdate: (state, action) => {
 *           // Optimistically assume the changes will be OK
 *           const { id, changes } = action.payload;
 *           mergeOrderChanges(state[id], changes);
 *       },
 *
 * This getting our pending changes and merging them into the current state. So in this case 'changes' is:
 * {
 *      x: true
 * }
 *
 * and that's merged into our current state object:
 * {
 *      x: false,
 *      y: false,
 *      z: false,
 *      number: 100
 * }
 *
 * where it becomes:
 * {
 *      x: true,
 *      y: false,
 *      z: false,
 *      number: 100
 * }
 *
 * On the off-chance the server had a failure in updating the x setting, it would just return an "x: false" in the response
 * and our checkbox would flip back to unchecked to reflect this failure. We would see a momentary "flicker" of it being
 * checked because of the onQueued but this should be a fringe case.
 *
 * Now that our local state is handled we send the request out to the server. This done through the API call we call in
 * the updateCall() function. sendOrderUpdate is a function that is exported from the api package that does the PATCH
 * to our mock server.
 *
 * Once the server updates its database it returns a copy of the most up-to-date state in a JSON response.
 * Now this is where the onUpdate() comes in.
 *
 * First, if we need to update anything RELATED to our state but not actually part of the state
 * we can call a custom handleUpdateOrderSuccess() local function with the changes we just applied.
 *
 * But the most important part is the resolve() of this method. Once the resolve() is called, it figures out how to
 * handle the server response in the "extraReducers" below. Down below you see:
 *
 *      builder.addCase(updateOrder.fulfilled, (state, action) => {
 *          updateOrderState({ state, id, order: updatedOrder, pendingChanges });
 *      });
 *
 * This is where we replace our current state with what we got from the server because we know that is the most current.
 * Let's say our state is:
 *
 * {
 *      x: true,
 *      y: false,
 *      z: false,
 *      number: 100
 * }
 *
 * But the response from the server was:
 * {
 *      x: true,
 *      y: false,
 *      z: false,
 *      number: 500 <---- THIS IS DIFFERENT!
 * }
 *
 * So in ANOTHER tab we might have changed our number to 500. But in this tab it's still 100. Replacing it with the current
 * state will make it 500 on this tab too. This is a fringe case, In actuality no real change should have to be made to the state thanks to our
 * pre-updating the state that we did back in onQueued.
 *
 * However, there's a problem. Let's say we have checkbox Y as TRUE as a pending change, but not yet sent to the server. When the server response
 * is returned we might lose that value. Look above, y would just be replaced with a false again, clearing out our checkbox, because the server didn't know of this change yet.
 * So after we replace our state we RE-MERGE our pending changes into that state again so that x and y are true. Even though the y update hasn't been sent to the server yet.
 *
 * Finally, let's talking about batching. The 'merge' function below is how we handle merging CHANGES into each other.
 * Let's say we click X and Y very fast, and then Z slightly after. We don't want to send THREE separate updates to the server, spamming it.
 * So we have a DELAY configured (usually 350 ms). As long as the requests are within that time window they are BATCHED together.
 * So our 'changes' object, batching, callback functions, and merge function does this:
 *
 * UPDATE #1 REQUESTED WITH A CALLBACK extraX() FUNCTION INCLUDED:
 * {
 *      x: true
 * }
 *
 * CHANGES OBJECT:
 * {
 *      x: true
 * }
 *
 * ----- 150 MS LATER
 *
 * UPDATE #2 REQUESTED WITH A CALLBACK extraY() FUNCTION INCLUDED:
 * {
 *      y: true
 * }
 *
 * UPDATE #2 IS MERGED INTO CHANGES OBJECT, CHANGES OBJECT IS NOW:
 * {
 *      x: true,
 *      y: true
 * }
 *
 * ----- 350 MS LATER (DEADLINE REACHED)
 *
 * ----- CHANGES OBJECT WITH X AND Y IS SENT TO SERVER
 *
 * ----- 50 MS LATER
 *
 * UPDATE #3 REQUESTED:
 * {
 *      z: true
 * }
 *
 * CHANGES OBJECT IS BACK TO JUST THIS, QUEUED AND WAITING FOR 350 MS DEADLINE:
 * {
 *      z: true
 * }
 *
 * ----- REPONSE FROM SERVER FOR CHANGES X AND Y
 * ----- extraX() IS EXECUTED
 * ----- extraY() IS EXECUTED
 * ----- STATE IS UPDATED (IF NEEDED)
 *
 */

/**
 * createConsolidatedUpdateAsyncThunk returns two pre-made functions for us to use:
 *      thunk (which is renamed here to updateOrder) - is what we call for dispatch-ing the update.
 *          This function is exported below for use by components (as `dispatch(updateOrder({ id, changes, callback }))`).
 *          This function also has two additional properties - fulfilled and rejected, which are used for adding extraReducers.
 *          The updateOrder.fulfilled reducer is triggered with the payload provided to the `resolve` function in `onUpdate`.
 *          The updateOrder.rejected reducer is triggered with the error as the payload when the update fails (this is not currently used here).
 *      getPendingChanges - is a helper function that gets the pending changes related to THIS thunk. It's not always needed but sometimes
 *          in other functions in this slice you might want to be aware of changes that will eventually come. In this slice it's the getOrder()
 *          function. The 'onQueued' function should take care of updating the state with the pending changes, this is just another safeguard
 *          where when requesting the order we also merge the pending changes into the state again so our getter is accurate.
 *
 * So we setup createConsolidatedUpdateAsyncThunk() by passing it how to handle on queued, on update, after update, and how to merge changes.
 * And in return it gives a simple packaged function that we can call to do the update known as 'thunk' but usually aliased into something like 'updateOrder'.
 */
const { thunk: updateOrder, getPendingChanges: getPendingOrderChanges } = createConsolidatedUpdateAsyncThunk(
    'orders/update',
    {
        onQueued: ({ id, changes }, { dispatch }) => {
            // Using the client-side "normal" reducers it will take the changes we want to make to the order
            // and update the state for the order on the client-side only
            // This is before we even send the updates to the server. We just assume everything will be updated
            // fine on the server-side
            dispatch(addPendingOrderUpdate({ id, changes }));
        },

        // The call we make to send the changes to the API. This function is used within spamProtection
        // in the sendQueuedChanges function. The 'id' is the orderID referenced in the OrderContext with the updateOrder call and the 'changes'
        // are all the batched changes when the update finally fires
        updateCall: async ({ id, changes }) => {
            // If the price recalc is in the changes, pass that along to the server to force a price recalculation
            const requiresPriceRecalculate = changes?.requiresPriceRecalculate;
            return sendOrderUpdate(id, changes, requiresPriceRecalculate);
        },

        // After we get a success from the server, what should we do locally?

        // In some cases we might want to update other things related to the order state, but not the actual order state. Like for
        // example if the site was one of the things changed in the orders, then we want to refetch the available ship methods
        // because those might have changed. They aren't part of the order state so they wont be updated automatically.
        // That's where handleUpdateOrderSuccess() comes in - not all update calls will have something like that.

        // They will have a resolve() function though - which is what eventually calls our extraReducer below and tells the client
        // to update their state with the results from the server

        // response - is the full JSON response from the server
        // appliedChanges - are the changes that got applied in the request send to the server
        // pendingChanges - are the changes that got queued up but have NOT been sent to the server yet
        // resolve - is a promise function, that is resolving the thunk and saves the updates to the store
        // thunkAPI - is a reference to the thunkAPI, it's passed in from spamProtection.js. The thunkAPI has a reference to dispatch as
        // per the documentation: https://redux-toolkit.js.org/api/createAsyncThunk
        onUpdate: ({ id, response, appliedChanges, pendingChanges, resolve, thunkAPI }) => {
            handleUpdateOrderSuccess({
                dispatch: thunkAPI.dispatch,
                id,
                response,
                appliedChanges,
            });
            // All this information is stored in a payload and sent to the reducer
            resolve({
                id,
                updatedOrder: response,
                appliedChanges,
                pendingChanges,
            });
        },

        // Determine how we merge the CHANGES into each other. This is not like the merge we do in the onQueued
        // method above where we are merging the changes into the order state. This is only consolidating our changes that will
        // be sent to the server.
        merge: mergeOrderChanges,
    }
);
export { updateOrder };

export const handleUpdateOrderSuccess = ({ dispatch, id, appliedChanges, response, indirectUpdate }) => {
    if (logger.isDebugEnabled()) {
        logger.debug('UPDATE_ORDER_SUCCESS', { id, appliedChanges, response, indirectUpdate });
    }
    if (indirectUpdate) {
        dispatch(getOrder({ id }));
    }

    // An UpdateOrder success response will return the latest Order details, but changes to an order
    // might mean we need to update other data that doesn't exist directly on the order as well
    let requiresShipDateOptionsUpdate = false;
    let requiresShipMethodsUpdate = false;
    if (appliedChanges.site) {
        requiresShipDateOptionsUpdate = true;
        requiresShipMethodsUpdate = true;
    }
    if (appliedChanges.serviceLevel || appliedChanges.shipDate) {
        requiresShipMethodsUpdate = true;
    }
    requiresShipDateOptionsUpdate && dispatch(getShipDateOptions(id));
    requiresShipMethodsUpdate && dispatch(getShipMethods(id));
};

const updateOrderState = ({ state, id, order, pendingChanges }) => {
    state[id] = order;
    // If there are still pending changes, optimistically assume those changes will be OK
    if (pendingChanges) {
        mergeOrderChanges(state[id], pendingChanges);
    }
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        cartID: undefined,
    },
    // Client-side reducers: when we don't need to handle a response from a server and it's just something
    // that is immediately done on client-side to update a state
    reducers: {
        addPendingOrderUpdate: (state, action) => {
            // Optimistically assume the changes will be OK
            const { id, changes } = action.payload;
            mergeOrderChanges(state[id], changes);
        },
    },
    // Server-side reducers: when we send a request to the server and we need to determine how to handle the response from that
    // server - success or failure - and then update the state accordingly
    extraReducers: (builder) => {
        builder.addCase(protectedGetCart.fulfilled, (state, action) => {
            const { id, order, pendingChanges } = action.payload;
            if (id) {
                state.cartID = id;
                updateOrderState({ state, id, order, pendingChanges });
            } else {
                state.cartID = null;
            }
            state.isCartLoading = false;
        });

        builder.addCase(protectedGetCart.pending, (state) => {
            state.isCartLoading = true;
        });


        builder.addCase(protectedGetCart.rejected, (state) => {
            state.isCartLoading = false;
        });

        builder.addCase(clearCart.fulfilled, (state) => {
            state.cartID = null;
        });
        builder.addCase(getOrder.fulfilled, (state, action) => {
            const { id, order, pendingChanges } = action.payload;
            updateOrderState({ state, id, order, pendingChanges });
        });

        builder.addCase(getOrder.rejected, (state, action) => {
            const rejectionDetails = action.payload;

            if (rejectionDetails.apiErrorType === APIError.TYPE.NOT_FOUND ||
                rejectionDetails.apiErrorType === APIError.TYPE.UNAUTHORIZED ||
                rejectionDetails.apiErrorType === APIError.TYPE.BAD_REQUEST) {
                // Handle the bad requests by setting state to empty object so the page still loads
                // but there is no data
                const orderID = action.meta.arg.id;
                updateOrderState({ state, id: orderID, order: orderNotFound });
            }
        });

        builder.addCase(updateOrder.fulfilled, (state, action) => {
            const { id, updatedOrder, pendingChanges } = action.payload;
            // Take the response object from the server and replace the order state with that as it should be the most
            // accurate up-to-date version
            // The pending changes haven't been sent to the server yet
            // Assume they are fine (like in onQueued) and, after we replace the state, merge them into the state

            // This is done to prevent 'flickering'. Consider the following:
            // 1) X as true is queued up, the state is updated with Change X, and then its sent to the server
            // 2) Y as true is queued up, the state is updated with Change Y, but it's waiting to be sent to the server
            // 3) Client-side state now has X and Y as true
            // 4) Server response comes back, it only knows about X being true
            // 5) Entire state is replaced with server response so Y is now false again
            // 6) As a safeguard we merge the pending queued changes (Y as true) into the state after it was replaced
            // If we didn't do that X and Y would be true on the client-side, then it would change to X only being true (meaning Y checkbox
            // would flicker off for a second) and then once the Y is true is returned from the server it would flicker
            // back on
            updateOrderState({ state, id, order: updatedOrder, pendingChanges });
        });
    },
});

export const { addPendingOrderUpdate } = ordersSlice.actions;

export default ordersSlice.reducer;
