/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import SimpleTag from './simpleTag';

/**
 * Left alignment tags
 * {left}Text to be left aligned{/left}
 */
export default new SimpleTag(
    'left',
    (key, contents) => {
        return (
            <div key={key} style={{ textAlign: 'left' }}>
                {contents}
            </div>
        );
    },
    (contents) => `\n${contents}\n`
);
