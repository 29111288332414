/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { getLogger } from '~utils/logging';

import api from './api';

const logger = getLogger(Symbol('API:TimeZone'));

const setTimeZoneID = async (id) => {
    api.put('timeZone', { timeZoneID: id });
};

const setTimeZoneOffsetMinutes = async (minutes) => {
    api.put('timeZone', { timeZoneOffsetMinutes: minutes });
};

export const set = async () => {
    try {
        return await setTimeZoneID(new Intl.DateTimeFormat().resolvedOptions().timeZone);
    } catch (err) {
        logger.debug('Problem setting time zone by ID', err);
        // Either the browser doesn't support Intl.DateTimeFormat, or the browser reported a TimeZone
        // that the server doesn't know about - fall back to the offset
        return setTimeZoneOffsetMinutes(new Date().getTimezoneOffset() * -1);
    }
};
