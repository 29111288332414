/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import * as rawTagRenderer from './rawTagRenderer';

export default class SingleAttributeToken {
    constructor(tagName, validAttributePattern, renderer, textRenderer) {
        this.upperTagName = tagName.toUpperCase();
        this.validAttributePattern = validAttributePattern;
        this.renderer = renderer;
        this.textRenderer = textRenderer;
    }

    get requireClose() {
        return false;
    }

    get tagName() {
        return this.upperTagName;
    }

    render({ parsedTag, keyGen }) {
        if (parsedTag.tagData.length < 2 || parsedTag.tagData[0] !== '=') {
            return rawTagRenderer.render(parsedTag, keyGen);
        }
        const attributeValue = parsedTag.tagData.slice(1).join('');
        if (!this.validAttributePattern.test(attributeValue)) {
            return rawTagRenderer.render(parsedTag, keyGen);
        }
        return this.renderer(keyGen.next, attributeValue);
    }

    renderAsText({ parsedTag }) {
        if (parsedTag.tagData.length < 2 || parsedTag.tagData[0] !== '=') {
            return rawTagRenderer.renderAsText(parsedTag);
        }
        const attributeValue = parsedTag.tagData.slice(1).join('');
        if (!this.validAttributePattern.test(attributeValue)) {
            return rawTagRenderer.renderAsText(parsedTag);
        }
        return this.textRenderer(attributeValue);
    }
}
