/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { automationIDPropTypes } from '~/components/id/AutomationID';
import { getProps as getMenuButtonARIA } from '~components/a11y/aria/menuButton';
import { withMenuContext } from '~components/a11y/MenuContext';
import FeatherIcon from '~components/images/icons/FeatherIcon';

import HeaderToolbarDropdown from './HeaderToolbarDropdown';
import HeaderToolbarPopup from './HeaderToolbarPopup';

const HeaderToolbarItemContents = ({
    automationID,
    label,
    icon,
    count,
    countType = 'secondary',
    page,
    menuActive,
    ariaDescription,
}) => {
    const { id } = automationID;
    const showCount = (typeof count === 'number' || typeof count === 'string') && count > 0;
    const iconClasses = ['header-icon'];
    if (showCount) {
        iconClasses.push('header-icon-with-badge');
        iconClasses.push(`with-badge-${countType}`);
    }
    const ariaMenuProps = (id && getMenuButtonARIA({ id, menuActive })) || {};
    const contents = (
        <div>
            <span className={iconClasses.join(' ')}>
                <FeatherIcon name={icon} />
                {showCount && <span className="count-label" aria-hidden="true">{count}</span>}
            </span>
            <span className="text-label">{label}</span>
        </div>
    );
    if (page) {
        return (
            // eslint-disable-next-line jsx-a11y/aria-props
            <a aria-description={ariaDescription} id={id} href={page.href} onClick={page.redirect} data-action={id} role="button" {...ariaMenuProps}>
                {contents}
            </a>
        );
    }
    return (
        <div data-action={automationID}>{contents}</div>
    );
};

const countTypePropType = PropTypes.oneOf(['primary', 'secondary', 'danger']);
HeaderToolbarItemContents.propTypes = {
    automationID: automationIDPropTypes,
    page: PropTypes.shape({
        redirect: PropTypes.func.isRequired,
        href: PropTypes.string.isRequired,
    }),
    count: PropTypes.any,
    countType: countTypePropType,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    menuActive: PropTypes.bool,
    ariaDescription: PropTypes.string,
};

const MENU_TOGGLE_PAGE = {
    href: '#',
    redirect: (e) => {
        e.preventDefault();
        e.stopPropagation();
    },
};

const HeaderToolbarItem = ({
    automationID,
    label,
    visibleOnMobile,
    hiddenOnMobile,
    icon,
    count,
    countType,
    className,
    page,
    popupClassName,
    popup,
    menuItems,
    menuToggleRef,
    menuRef,
    menuActive,
    ariaDescription,
}) => {
    const classes = ['toolbar-item'];
    if (className) {
        classes.push(className);
    }
    if (menuActive) {
        classes.push('expanded');
    }
    if (hiddenOnMobile) {
        classes.push('hidden-on-mobile');
    } else if (visibleOnMobile) {
        classes.push('visible-on-mobile');
    }

    let pageOrMenuToggle = page;
    if (!page && (menuItems || popup)) {
        pageOrMenuToggle = MENU_TOGGLE_PAGE;
    }

    const commonItemProps = { automationID, label, icon, count, countType, page: pageOrMenuToggle, ariaDescription };
    if (menuItems || popup) {
        return (
            <div ref={menuToggleRef} className={classes.join(' ')}>
                <HeaderToolbarItemContents {...commonItemProps} automationID={automationID} menuActive={menuActive}/>
                {menuItems && (
                    <HeaderToolbarDropdown automationID={automationID} ref={menuRef}>
                        {menuItems}
                    </HeaderToolbarDropdown>
                )}
                {popup && (
                    <HeaderToolbarPopup automationID={automationID} ref={menuRef} className={popupClassName}>
                        {popup}
                    </HeaderToolbarPopup>
                )}
            </div>
        );
    }
    return (
        <div className={classes.join(' ')}>
            <HeaderToolbarItemContents {...commonItemProps} />
        </div>
    );
};

HeaderToolbarItem.propTypes = {
    automationID: automationIDPropTypes.isRequired,
    page: PropTypes.shape({
        redirect: PropTypes.func.isRequired,
        href: PropTypes.string.isRequired,
    }),
    count: PropTypes.any,
    countType: countTypePropType,
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    popupClassName: PropTypes.string,
    popup: PropTypes.node,
    menuItems: PropTypes.node,
    visibleOnMobile: PropTypes.bool,
    hiddenOnMobile: PropTypes.bool,
    menuToggleRef: PropTypes.func.isRequired,
    menuRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
    menuActive: PropTypes.bool.isRequired,
    ariaDescription: PropTypes.string,
};

export default withMenuContext({ opensDown: true })(HeaderToolbarItem);