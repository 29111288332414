/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

const getProps = ({ menuID, menuActive }) => {
    return {
        role: 'button',
        'aria-haspopup': true,
        'aria-controls': menuID,
        'aria-expanded': menuActive,
    };
};

export { getProps };
