/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import { automationIDPropTypes } from '~/components/id/AutomationID';
import { normalizeColorWithContrast } from '~utils/colors/normalizeColor';

import VALID_SHAPES from './VALID_SHAPES';

/**
 * The base component of all Icon types.
 *
 * Note: This should never be used directly (except when implementing specific Icon types).
 *       Use FeatherIcon or MaterialIcon (or, if library is dynamic, Icon) instead.
 */
const IconBase = ({ automationID, children, className, size, shape, color, filled, title }) => {
    const iconStyle = {
        fontSize: size || undefined,
    };
    const outerClasses = ['icon', className];
    if (color) {
        outerClasses.push(`icon-color`);
        const colorWithContrast = normalizeColorWithContrast(color);
        if (shape && filled) {
            // Filled: the background color is the color, and the icon color is the calculated contrast color
            iconStyle.backgroundColor = colorWithContrast.color;
            iconStyle.color = colorWithContrast.contrast;
        } else {
            // None or Border-Only: The icon color and border color are the color
            iconStyle.color = colorWithContrast.color;
        }
    }

    if (shape) {
        outerClasses.push(`icon-shape-${shape}`);
        if (filled) {
            outerClasses.push(`icon-filled`);
        }
    }

    return <i id={automationID && automationID.id} style={iconStyle} className={outerClasses.join(' ')} aria-hidden title={title}>{children}</i>;
};

IconBase.propTypes = {
    automationID: automationIDPropTypes,
    className: PropTypes.string.isRequired,
    children: PropTypes.node,
    color: PropTypes.string,
    shape: PropTypes.oneOf([...VALID_SHAPES, undefined]),
    filled: PropTypes.bool,
    size: PropTypes.any,
    title: PropTypes.string,
};

export default IconBase;
