/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createSlice } from '@reduxjs/toolkit';

import { get as fetchReviewByID, poll as pollReviews } from '~api/ordering/reviews';
import createSimpleAPIAsyncThunk from '~common-store/helpers/createSimpleAPIAsyncThunk';


export const getReviewByID = createSimpleAPIAsyncThunk('reviews/getByID', fetchReviewByID);
export const getCurrentReviews = createSimpleAPIAsyncThunk('reviews/getCurrentReviews', pollReviews);



const reviewsSlice = createSlice({
    name: 'reviews',
    initialState: {
        reviews: {},
        currentReviews: [],
    },
    reducers: {
        setCurrentReviews(state, action) {
            state['currentReviews'] = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getReviewByID.fulfilled, (state, action) => {
            const review = action.payload;
            state.reviews[review.id] = review;
        });
        builder.addCase(getReviewByID.rejected, (state, action) => {
            // We got a 404 - put a null in there so page knows it doesn't exist
            state.reviews[action.meta.arg] = null;
        });
        builder.addCase(getCurrentReviews.fulfilled, (state, action) => {
            state.currentReviews = action.payload;
        });
    },
});

const { actions, reducer } = reviewsSlice;
export const { setCurrentReviews } = actions;
export default reducer;