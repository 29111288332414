/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import equal from 'fast-deep-equal';
import { useEffect, useRef } from 'react';

const useDeepEqualMemo = (deps) => {
    const ref = useRef(deps);
    if (!equal(deps, ref.current)) {
        ref.current = deps;
    }
    return ref.current;
};

/**
 * In situations where useEffect deps are arrays, and developers might be creating new, identical arrays or objects each time,
 * the standard useEffect deps check will think the deps change, even though they aren't changed as far as we are concerned.
 * When reasonable, fix the caller so the deps are strictly equal every time, but if it's not reasonable to expect developers
 * to not create new arrays/objects each time, use this instead.
 */
const useEffectDeepEqualDeps = (effect, deps) => {
    const depsMemo = useDeepEqualMemo(deps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useEffect(effect, depsMemo);
};

export default useEffectDeepEqualDeps;
