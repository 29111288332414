/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

export default class SimpleToken {
    constructor(tagName, renderer, textReplacement) {
        this.upperTagName = tagName.toUpperCase();
        this.renderer = renderer;
        this.textReplacement = textReplacement;
    }

    get requireClose() {
        return false;
    }

    get tagName() {
        return this.upperTagName;
    }

    render({ keyGen }) {
        return this.renderer(keyGen.next);
    }

    renderAsText() {
        return this.textReplacement;
    }
}
