/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { get as fetchLocalizations } from '~api/localization';
import { getLogger } from '~utils/logging';

import clientSideCache from '../helpers/clientSideCache';

const logger = getLogger(Symbol('CommonStore:Slices:Localization'));

const CACHE_KEY = 'localization';

const previousCache = clientSideCache.load(CACHE_KEY, {});
const initialState = Object.freeze(Object.assign({}, previousCache, { cachedKeys: Object.keys(previousCache) }));

const LOOKUP_DELAY = 10;

const pendingLookups = new Set();
let queuedLookups = new Set();
let queuedLookupTimeoutID = null;

const getDelayedGetLocalizations = (resolve, rejectWithValue) => async () => {
    const keys = queuedLookups;
    if (logger.isInfoEnabled()) {
        logger.info(`delayedGetLocalizations keys ${JSON.stringify(...keys)}`);
    }
    queuedLookupTimeoutID = null;
    queuedLookups = new Set();
    for (const key of keys) {
        pendingLookups.add(key);
    }
    try {
        const localizations = await fetchLocalizations(keys);
        for (const key of keys) {
            pendingLookups.delete(key);
        }
        resolve(localizations);
    } catch (error) {
        resolve(rejectWithValue([...keys]));
    }
};

const isLocalizationKnown = (state, key) => {
    const localization = state.localization;
    return Object.prototype.hasOwnProperty.call(localization, key) && !localization.cachedKeys.includes(key);
};

const queueLookupIfNeeded = ({ state, key }) => {
    if (isLocalizationKnown(state, key)) {
        // Localized value for this key is already known, nothing to do
        if (logger.isDebugEnabled()) {
            logger.debug(`localization[${key}] already known`);
        }
    } else if (pendingLookups.has(key)) {
        // Localized value for this key has already been requested, nothing to do
        if (logger.isDebugEnabled()) {
            logger.debug(`localization[${key}] already in the process of being fetched`);
        }
    } else if (queuedLookups.has(key)) {
        // This key has already been added to the lookup queue
        if (logger.isDebugEnabled()) {
            logger.debug(`localization[${key}] already queued to be fetched`);
        }
    } else {
        if (logger.isDebugEnabled()) {
            logger.debug(`localization[${key}] is now queued to be fetched`);
        }
        queuedLookups.add(key);
    }
};

export const getLocalizations = createAsyncThunk(
    'localizations/get',
    async (_keys, { rejectWithValue }) => {
        // Queueing the keys is handled in condition below
        // This only gets executed if some lookup was queued, and the lookup timeout wasn't triggered yet
        logger.debug('delayedGetLocalizations is now scheduled');
        return new Promise((resolve) => {
            queuedLookupTimeoutID = setTimeout(getDelayedGetLocalizations(resolve, rejectWithValue), LOOKUP_DELAY);
        });
    }, {
        condition: (keys, { getState }) => {
            if (logger.isDebugEnabled()) {
                logger.debug(`getLocalizations action called with keys ${JSON.stringify(keys)}`);
            }

            if (!keys || keys.length === 0) {
                return false;
            }
            const state = getState();
            keys.forEach((key) => {
                queueLookupIfNeeded({ state, key });
            });
            if (queuedLookups.size > 0) {
                return queuedLookupTimeoutID === null;
            }
            return false;
        },
    }
);

export const getLocalization = (key) => {
    return getLocalizations([key]);
};

const ajaxStateSlice = createSlice({
    name: 'localization',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getLocalizations.fulfilled, (state, action) => {
            if (action.payload) {
                for (const [key, value] of Object.entries(action.payload)) {
                    updateState(state, key, value);
                }
            }
        });
        builder.addCase(getLocalizations.rejected, (state, action) => {
            if (action.payload) {
                for (const key of action.payload) {
                    updateState(state, key, state[key] || '', false);
                    state.cachedKeys.push(key);
                }
            }
        });
    },
});

const updateState = async (draft, key, pattern, cache = true) => {
    draft[key] = pattern;
    if (cache) {
        const cachedKeyIndex = draft.cachedKeys.indexOf(key);
        if (cachedKeyIndex !== -1) {
            draft.cachedKeys.splice(cachedKeyIndex, 1);
        }
        clientSideCache.save(CACHE_KEY, Object.assign({}, draft, { cachedKeys: undefined }));
    }
};

export default ajaxStateSlice.reducer;
