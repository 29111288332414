/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fixURL } from '~instance';


const PLACEHOLDER_PREFIX = 'data:image/svg+xml;,<svg version="1.1" xmlns="http://www.w3.org/2000/svg"';
const PLACEHOLDER_SUFFIX =
    'viewBox="0 0 1 1"><rect x="0" y="0" width="1" height="1" fill="rgba(128,128,128,0.15)" /></svg>';

const PLACEHOLDER_PATTERN = /^placeholder:(\d+)x(\d+)$/;
const pendingRequests = new Set();

const smartImagesPath = fixURL('/skin/images/');
const keyPattern = /^[a-zA-Z0-9]+$/;

export const getSmartImageURL = (key) => {
    if (!keyPattern.test(key)) {
        throw new Error(`Invalid smartImage key: ${key}`);
    }
    return `${smartImagesPath}${key}`;
};

// TODO MTM webcrd_dev-34 - Remove the BOR related smart images from the client-side
const createPlaceholder = (name, width, height) => {
    return {
        name,
        isSVG: false,
        src: `${PLACEHOLDER_PREFIX} width="${width}" height="${height}" ${PLACEHOLDER_SUFFIX}`,
    };

};

const preloadSmartImage = async (name) => {
    const src = getSmartImageURL(name);
    const fetchPromise = fetch(src, { method: 'HEAD' });

    // Preload the actual image for use
    const link = document.createElement('link');
    link.rel = 'preload';
    link.as = 'image';
    link.href = src;
    document.head.appendChild(link);

    const response = await fetchPromise;
    if (!response.ok) {
        throw new Error('Bad Response for image');
    }
    const contentType = response.headers.get('Content-Type') || '';
    const isSVG = contentType.startsWith('image/svg+xml');
    return {
        name,
        isSVG,
        src,
    };
};

export const getSmartImage = createAsyncThunk(
    'smartImages/get',
    async (name, { rejectWithValue }) => {
        const placeholderMatch = PLACEHOLDER_PATTERN.exec(name);
        if (placeholderMatch) {
            // Create a placeholder image only if the name itself is "placeholder:{width}x{height}"
            // This means the dev explicitly placed a placeholder here while we are loading the real image
            return createPlaceholder(name, placeholderMatch[1], placeholderMatch[2]);
        } else {
            try {
                pendingRequests.add(name);
                return await preloadSmartImage(name);
            } catch (error) {
                return rejectWithValue({
                    name,
                    error: error.message,
                });
            } finally {
                pendingRequests.delete(name);
            }
        }
    }, {
        condition: (name, { getState }) => {
            if (pendingRequests.has(name)) {
                return false;
            }
            // Don't re-request an image you already have in the state
            const state = getState();
            return !state.smartImages[name];
        },
    }
);

const initialState = Object.freeze({});

const smartImagesSlice = createSlice({
    name: 'smartImages',
    initialState,
    reducers: {
        // No standard reducers
    },
    extraReducers: (builder) => {
        builder.addCase(getSmartImage.fulfilled, (state, action) => {
            const { name, isSVG, src } = action.payload;
            state[name] = {
                isSVG,
                src,
            };
        });
        builder.addCase(getSmartImage.rejected, (state, action) => {
            if (action.payload) {
                state[action.payload.name] = {
                    missing: true,
                };
            }
        });
    },
});

export default smartImagesSlice.reducer;
