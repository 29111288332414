/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PlaceholderText from '~components/skeleton/PlaceholderText';

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeToken from './singleAttributeToken';

/**
 * Shows placeholder text.
 * {placeholder=Text describing what the placeholder is for}
 */
export default new SingleAttributeToken(
    'placeholder',
    validAttributePattern,
    (key, attributeValue) => {
        return <PlaceholderText key={key} placeholderFor={attributeValue} />;
    },
    () => ''
);
