/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { fixURL } from '~instance';

import { ANYTHING_BUT_CURLY_AND_QUOTE as validAttributePattern } from './attributePatterns';
import SingleAttributeTag from './singleAttributeTag';

/**
 * Turns text into a link to a given URL.
 * {link=/path/for/link}Link text{/link}
 *
 * See also popup
 */
export default new SingleAttributeTag(
    'link',
    validAttributePattern,
    (key, attributeValue, contents) => {
        return (
            <a key={key} href={fixURL(attributeValue)}>
                {contents}
            </a>
        );
    },
    (_attributeValue /* unused in text formatting */, contents) => contents
);
