/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import debounce from 'debounce';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useState } from 'react';

import immerObject from './immerObject';

const DEBUG = {};
globalThis.DEBUG = DEBUG;

const [getInternalDebugState, updateInternalDebugState] = immerObject({});

const extract = (state, category, key) => {
    const stateCategory = state[category];
    if (stateCategory) {
        return stateCategory[key];
    }
    return undefined;
};

const set = (state, category, key, value) => {
    state[category] = state[category] || {};
    state[category][key] = value;
};

const debugStateListeners = new Set();
const notifyDebugStateListeners = debounce(() => {
    const debugState = getInternalDebugState();
    for (const debugStateListener of debugStateListeners) {
        debugStateListener(debugState);
    }
}, 10);

export const setDebugState = (category, key, value) => {
    updateInternalDebugState((draft) => set(draft, category, key, value));
    notifyDebugStateListeners();
};

export const getDebugState = (category, key) => extract(getInternalDebugState(), category, key);

export const addDebugMethod = (category, key, method) => set(DEBUG, category, key, method);

const DebugContext = createContext(null);

const DebugProvider = ({ children }) => {
    const [state, setState] = useState(getInternalDebugState());

    useEffect(() => {
        debugStateListeners.add(setState);
        return () => {
            debugStateListeners.delete(setState);
        };
    }, [setState]);

    return <DebugContext.Provider value={state}>{children}</DebugContext.Provider>;
};

DebugProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { DebugProvider };

export const useDebugState = (category, key) => {
    const state = useContext(DebugContext);
    return extract(state, category, key);
};
