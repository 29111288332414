/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import { useMemo } from 'react';

const useBottomEntryHeaderOptions = () => {
    const pages = null;
    const toolbarOptions = null;
    return useMemo(
        () => ({
            pages,
            toolbarOptions,
        }),
        [pages, toolbarOptions]
    );
};

export default useBottomEntryHeaderOptions;
