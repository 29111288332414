/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import LegacyImage from '~components/images/LegacyImage';

import { ANYTHING_BUT_CURLY_AND_QUOTE } from './attributePatterns';
import MultiAttributeToken from './multiAttributeToken';

/**
 * Shows an image.
 * Single attribute form:
 *   {image=/path/to/image} => Shows an image at the given path
 * Multi-attribute form:
 *   {image src=/path/to/image alt=Alt text}
 *   - src: Required, path to the image
 *   - alt: Optional alt text (text for screen readers only)
 *
 * See also smartImage
 */
export default new MultiAttributeToken(
    'image',
    [
        {
            name: 'src',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
            allowAsSingle: true,
            isRequired: true,
        },
        {
            name: 'alt',
            pattern: ANYTHING_BUT_CURLY_AND_QUOTE,
        },
    ],
    (key, attributes) => {
        return <LegacyImage key={key} src={attributes.src} alt={attributes.alt || ''} />;
    },
    () => ''
);
