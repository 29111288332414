/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';

import IconBase from './IconBase';
import withIconButton from './withIconButton';
import withIconContainerWithAction from './withIconContainerWithAction';
import withIconTooltip from './withIconTooltip';

const normalizeIconName = (name) => {
    return name.toLowerCase().replace(' ', '-');
};

/**
 * See https://feathericons.com/ for a list of icons
 */
const FeatherIcon = ({ name, ...iconBaseProps }) => {
    return <IconBase className={`feathericon-${normalizeIconName(name)}`} {...iconBaseProps} />;
};

FeatherIcon.propTypes = {
    name: PropTypes.string.isRequired,
};

export default FeatherIcon;

export const FeatherIconButton = withIconButton(FeatherIcon);
export const FeatherIconContainerWithAction = withIconContainerWithAction(FeatherIcon);
export const FeatherIconWithTooltip = withIconTooltip(FeatherIcon);
