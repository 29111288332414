/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import deepEqual from 'fast-deep-equal';

import deepCopy from '~/utils/objects/deepCopy';
import { getLogger } from '~utils/logging';

import { mergedRangesCopy } from './mergeRanges';

const logger = getLogger(Symbol('CommonStore:Helpers:Merge'));

/**
 * Deep-merges the specified updates into the specified source.
 * For any arrays in the source...
 * - If the updates specify an ID, the updates will be merged by ID (see mergeByID).
 * - If the updates do not specify an ID, the updates will be appended to the array.
 *
 * WARNING: This only works reliably for data that can be represented as JSON, and where
 * the source and updates have matching structures.
 * Non-simple data (functions, Dates, circular references, etc) is NOT supported.
 *
 * @template {object | Array<*>} T
 * @param {T} source - The source to update with the given updates
 * @param {T} updates - The updates to merge into the source
 * @param {object} fullReplacements - An optional object that flags keys where the children should be fully replaced, not merged.
 */
const merge = (source, updates, fullReplacements) => {
    if (updates === null || updates === undefined) {
        return;
    }
    if (Array.isArray(source)) {
        mergeArray(source, updates, fullReplacements);
    } else {
        mergeObject(source, updates, fullReplacements);
    }
};

const mergeArray = (source, updates, fullReplacements) => {
    updates.forEach((subUpdates) => {
        if (typeof subUpdates === 'object' && 'id' in subUpdates) {
            mergeByID(source, subUpdates.id, subUpdates, fullReplacements);
        } else if (!subUpdates.DELETE && !subUpdates.DO_NOT_MERGE) {
            source.push(deepCopy(subUpdates));
        }
    });
};

const mergeObject = (source, updates, fullReplacements) => {
    for (const [key, subUpdates] of Object.entries(updates)) {
        const subSource = source[key];
        let mergeSub;
        let copy;
        const childFullReplacements = fullReplacements && fullReplacements[key];
        if (childFullReplacements === true) {
            mergeSub = false;
        } else if (typeof childFullReplacements === 'function') {
            mergeSub = false;
            copy = childFullReplacements(subSource, subUpdates);
        } else if (Array.isArray(subSource) && Array.isArray(subUpdates)) {
            if (childFullReplacements === 'range') {
                mergeSub = false;
                copy = mergedRangesCopy(subSource, subUpdates, merge);
            } else {
                mergeSub = true;
            }
        } else if (subSource === null || subUpdates === null) {
            mergeSub = false;
        } else if (typeof subSource === 'object' && typeof subUpdates === 'object') {
            mergeSub = true;
        } else {
            mergeSub = false;
        }
        if (mergeSub) {
            merge(subSource, subUpdates, childFullReplacements);
        } else {
            if (copy === undefined) {
                copy = deepCopy(subUpdates);
            }
            source[key] = copy;
        }
    }
};

/**
 * Merges the specified updates into an array, matching existing elements based on ID.
 * If the specified collection includes an object with the specified ID, the updates will be merged into that existing object.
 * If the specified collection does not include an object with the specified ID, the updates will be pushed to the end of the array.
 *
 * WARNING: This only works reliably for data that can be represented as JSON.
 * Non-simple data (functions, Dates, circular references, etc) is NOT supported.
 *
 * @param {Array<{id: string | number}>} collection - An array of objects with IDs to merge updates into
 * @param {string|number} id - The ID to update (must match updates.id)
 * @param {object} updates - The object to update.
 *                         If updates.DELETE is truthy, the element with the given ID will be removed from the array.
 *                         If the element exists in the collection already, updates will be merged with that element in the array.
 *                         If the element doesn't exist in the collection, updates will be appended to teh end of the array.
 * @param {object} fullReplacements - An optional object that flags keys where the children should be fully replaced, not merged.
 * @throws {Error} if the provided `id` doesn't match `updates.id`
 */
const mergeByID = (collection, id, updates, fullReplacements) => {
    if (id !== updates.id) {
        throw new Error("The provided ID doesn't match update ID");
    }
    if (updates.DELETE) {
        const sourceIndex = collection.findIndex((element) => element.id === id);
        if (sourceIndex !== -1) {
            collection.splice(sourceIndex, 1);
        }
    } else {
        const source = collection.find((element) => element.id === id);
        if (source === undefined) {
            collection.push(deepCopy(updates));
        } else {
            merge(source, updates, fullReplacements);
        }
    }
};

/**
 * Creates a new object that is includes the source merged with the updates.
 * The source and updates are left unchanged.
 *
 * WARNING: This only works reliably for data that can be represented as JSON.
 * Non-simple data (functions, Dates, circular references, etc) is NOT supported.
 *
 * @template {object | Array<*>} T
 * @param {T} source - The source to copy and then update with the given updates
 * @param {T} updates - The updates to merge into the source
 * @param {object} fullReplacements - An optional object that flags keys where the children should be fully replaced, not merged.
 */
const mergedCopy = (source, updates, fullReplacements) => {
    const sourceCopy = deepCopy(source);
    merge(sourceCopy, updates, fullReplacements);
    return sourceCopy;
};

/**
 * Extracts only the changes between an original and an updated copy.
 *
 * The change comparison makes several assumptions...
 * - The updated object is assumed to contain all of the keys the original has (potentially more, but never less).
 * -- If something is removed from the original, the updates object should explicitly specify null or some other appropriate "empty" value, not undefined.
 * - The original and updated are (mostly) single-layer
 * -- If a child is an object, and ANY of the fields are different, the entire object is included in the changes, not just the field(s) that change
 * -- If a child is an array, and ANY of the array elements are different (including if the sort order is changed), the entire array is included in the changes
 *
 * WARNING: This only works reliably for data that can be represented as JSON.
 * Non-simple data (functions, Dates, circular references, etc) is NOT supported.
 */
const getChangesOnly = (original, updated) => {
    const updates = deepCopy(updated);
    for (const [key, value] of Object.entries(updates)) {
        const originalValue = original[key];
        if (deepEqual(value, originalValue)) {
            delete updates[key];
        }
    }
    return updates;
};

/**
 * Wraps a provided function with logging if an error occurs (the error is re-thrown).
 *
 * @template WRAPPED
 * @param {WRAPPED} fn - The function to wrap
 * @returns {WRAPPED} The function, wrapped with logging
 */
const wrapWithLogging = (fn) => {
    return (...args) => {
        try {
            return fn(...args);
        } catch (e) {
            logger.error(`Problem with ${fn.name}`, { e, args });
            throw e;
        }
    };
};
/**
 * Fetch an object to inject into another object. Useful when you have circular dependencies. An order has reference to a paymentReview, but
 * the paymentReview also has a reference to the same order. We can't import them in the mockData because we have a chicken/egg situtation. So
 * instead we'll just put a placeholder in the mockDB with a 'fetchID' property. That will be the ID of whatever we need to eventually fetch.
 * Then on every individual request we'll fetch the foreign object (review) and inject into into the object (order) before we send the object out.
 * This way we have all our database objected loaded up without circular dependencies and then we can fetch and use them after the fact. The
 * circular dependency is prevented this way because we'll have an order with a ful paymentReview object, but because we aren't going through the
 * apiServer again that paymentReview object will only have a fetchID object inside it.
 *
 * @param {*} db - the database
 * @param {*} object - the object we are injecting into
 * @param {*} selfKey - the key of the object to return to the self, we need make this undefined in the fetched object to kill the circular loop
 * @param {*} keyToReplace - the fetchID of the foreign object we are fetching that will be injected into an object
 * @param {*} dbName - the name of the database to fetch that foreign object
 * @returns {object} an object with a foreign object injected into it
 */
export const fetchForeignObject = (db, object, selfKey, keyToReplace, dbName) => {
    // Get a copy of the object because we are going to modify for this particular case only (insert fetched objects)
    const copiedObject = deepCopy(object);

    // Get the id we are going to use to fetch (order.paymentReview.fetchID)
    const fetchID = copiedObject[keyToReplace]?.fetchID;

    if (fetchID) {
        // Fetch that object using the ID (get the paymentReview from the DB)
        const fetchedObject = db.get(dbName).getById(fetchID).value();

        // Get a copy of the fetched object because we are going to modify for this particular case only (nullify the loop)
        const copiedFetchedObject = deepCopy(fetchedObject);

        // Kill the circular dependency (we are fetching the paymentReview for the order, make sure the order in the paymentReview doesn't link back to an order object)
        copiedFetchedObject[selfKey] = undefined;

        // Inject the fetched object (paymentReview) into our original one (order)
        copiedObject[keyToReplace] = copiedFetchedObject;
    }
    return copiedObject;
};

const wrappedMergeByID = wrapWithLogging(mergeByID);
const wrappedMerge = wrapWithLogging(merge);
const wrappedMergedCopy = wrapWithLogging(mergedCopy);
const wrappedGetChangesOnly = wrapWithLogging(getChangesOnly);

export {
    wrappedMergeByID as mergeByID,
    wrappedMerge as merge,
    wrappedMergedCopy as mergedCopy,
    wrappedGetChangesOnly as getChangesOnly,

};
