/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { Row as BRow } from 'reactstrap';

const Row = ({ children, justify, justifyCenter, alignItemsCenter }) => {
    const classes = [];
    if (justify) {
        classes.push('justify');
    }

    if (justifyCenter) {
        classes.push('justify-content-center');
    }

    if (alignItemsCenter) {
        classes.push('align-items-center');
    }
    return <BRow className={classes.join(' ')}>{children}</BRow>;
};

Row.propTypes = {
    children: PropTypes.node.isRequired,
    justify: PropTypes.bool,
    justifyCenter: PropTypes.bool,
    alignItemsCenter: PropTypes.bool,
};

export default Row;
