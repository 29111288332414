/*
 * WebCRD
 * Web to print solution that automates ordering, fulfillment, job ticketing, production management and chargebacks across corporate print centers.
 * Copyright 1999-2025 Rochester Software Associates (service@rocsoft.com)
 */

import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { FormFeedback, Input } from 'reactstrap';

import Icon from '~components/images/Icon';
import localizedStringOrNode, { localizedStringOrNodePropTypes } from '~components/text/LocalizedStringOrNode';

import { automationIDPropTypes, getExtraAutomationID } from '../id/AutomationID';

import FormHelp from './FormHelp';
import RequiredFieldIndicator from './RequiredFieldIndicator';

/**
 * Shows the number of characters currently typed (N) and the total number of characters allowed (M)
 * for TextArea inputs that have a max length.
 */
const TextAreaNOfM = ({ value, maxLength }) => {
    if (!maxLength || maxLength < 1) {
        return null;
    }
    const length = (value && value.length) || 0;
    const classNames = ['form-text', 'textarea-n-of-m'];
    if (length >= maxLength) {
        classNames.push('textarea-at-maxlength');
    }
    return (
        <div className={classNames.join(' ')}>
            {length} / {maxLength}
        </div>
    );
};

TextAreaNOfM.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxLength: PropTypes.number,
};

const usePattern = ({ pattern, value }) => {
    const patternRegExp = useMemo(() => {
        return (pattern && new RegExp(pattern)) || undefined;
    }, [pattern]);

    const [patternValid, setPatternValid] = useState(() => {
        if (!patternRegExp) {
            return true;
        }
        return patternRegExp.test(value);
    });

    return {
        patternRegExp,
        patternValid,
        setPatternValid,
    };
};

const useEvents = ({ setValue, onEnter, value, onBlur, patternRegExp, setPatternValid }) => {
    const onInput = useCallback(
        (event) => {
            setValue(event.target.value);
        },
        [setValue]
    );

    const onKeyPress = useCallback(
        (event) => {
            if (event.charCode === 13 && onEnter) {
                onEnter();
            }
        },
        [onEnter]
    );

    const fullOnBlur = useCallback(
        (event) => {
            onBlur && onBlur(event);
            const trimmedValue = typeof value === 'string' ? value.trim() : value;
            if (trimmedValue !== value) {
                setValue(trimmedValue);
            }
            if (patternRegExp) {
                setPatternValid(patternRegExp.test(trimmedValue));
            }
        },
        [onBlur, value, patternRegExp, setPatternValid, setValue]
    );

    return {
        onInput,
        onKeyPress,
        fullOnBlur,
    };
};

const TypedInputState = ({ hasStateIcon, stateIcon, stateText, stateAriaLabel, valid, validReason, patternValid, patternMessage, invalid, invalidReason }) => {
    return (
        <>
            {stateText && (
                <div className="state-icon" aria-label={stateAriaLabel}>
                    {stateText}
                </div>
            )}
            {hasStateIcon && stateIcon && (
                <div className="state-icon" aria-label={stateAriaLabel}>
                    <Icon name={stateIcon}/>
                </div>
            )}
            {valid && validReason && (
                <FormFeedback className="form-text" valid>
                    {localizedStringOrNode(validReason)}
                </FormFeedback>
            )}
            {!patternValid && patternMessage && (
                <FormFeedback className="form-text">{localizedStringOrNode(patternMessage)}</FormFeedback>
            )}
            {invalid && invalidReason && (
                <FormFeedback className="form-text">{localizedStringOrNode(invalidReason)}</FormFeedback>
            )}
        </>
    );
};

TypedInputState.propTypes = {
    hasStateIcon: PropTypes.bool,
    stateText: PropTypes.string,
    stateIcon: PropTypes.string,
    stateAriaLabel: PropTypes.string,
    valid: PropTypes.bool,
    validReason: localizedStringOrNodePropTypes,
    patternValid: PropTypes.bool,
    patternMessage: localizedStringOrNodePropTypes,
    invalid: PropTypes.bool,
    invalidReason: localizedStringOrNodePropTypes,
};

const TypedInput = ({
    automationID,
    type,
    label,
    help,
    value, setValue,
    maxLength,
    autoComplete,
    required,
    pattern, patternMessage,
    inputRef,
    onBlur, onEnter,
    valid, validReason,
    invalid, invalidReason,
    stateIcon,
    stateText,
    stateAriaLabel,
    minValue, maxValue,
    rows,
    placeholder,
    ariaLabel,
    ariaDescription,
    step,
}) => {
    const helpID = help && automationID && `${automationID.id}__help`;

    const { patternRegExp, patternValid, setPatternValid } = usePattern({ pattern, value });
    const { onInput, onKeyPress, fullOnBlur } = useEvents({ setValue, onEnter, value, onBlur, patternRegExp, setPatternValid });

    const hasStateIcon = valid || invalid || !patternValid || !!stateIcon || !!stateText;
    const formGroupClasses = [];

    label && formGroupClasses.push('form-floating');
    hasStateIcon && formGroupClasses.push('has-state-icon');

    const labelAutomationID = getExtraAutomationID(automationID, 'label');

    return (
        <div className={formGroupClasses.join(' ')}>
            <Input
                innerRef={inputRef}
                type={type}
                autoComplete={autoComplete}
                id={automationID?.id}
                value={value}
                onInput={onInput} onKeyPress={onKeyPress} onBlur={fullOnBlur}
                disabled={!setValue}
                maxLength={maxLength}
                valid={valid} invalid={invalid || !patternValid}
                rows={rows}
                required={required}
                aria-label={ariaLabel}
                // eslint-disable-next-line jsx-a11y/aria-props
                aria-description={ariaDescription}
                pattern={pattern}
                placeholder={placeholder || '...'} /* required for the label to show properly */
                min={minValue} max={maxValue}
                step={step}
            />
            {label &&
                <label id={labelAutomationID && labelAutomationID.id} htmlFor={automationID && automationID.id}>
                    {localizedStringOrNode(label)}
                    {required && <RequiredFieldIndicator />}
                </label>
            }
            <TypedInputState
                stateText={stateText}
                hasStateIcon={hasStateIcon}
                stateIcon={stateIcon}
                valid={valid}
                validReason={validReason}
                patternValid={patternValid}
                patternMessage={patternMessage}
                invalid={invalid}
                invalidReason={invalidReason}
                stateAriaLabel={stateAriaLabel}
            />
            {type === 'textarea' && setValue && <TextAreaNOfM value={value} maxLength={maxLength} />}
            <FormHelp helpID={helpID} help={help} />
        </div>
    );
};

TypedInput.propTypes = {
    automationID: automationIDPropTypes,
    type: PropTypes.string.isRequired,
    label: localizedStringOrNodePropTypes,
    help: localizedStringOrNodePropTypes,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setValue: PropTypes.func,
    maxLength: PropTypes.number,
    inputRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    onEnter: PropTypes.func,
    onBlur: PropTypes.func,
    autoComplete: PropTypes.string,
    required: PropTypes.bool,
    pattern: PropTypes.string,
    patternMessage: localizedStringOrNodePropTypes,
    valid: PropTypes.bool,
    validReason: localizedStringOrNodePropTypes,
    invalid: PropTypes.bool,
    invalidReason: localizedStringOrNodePropTypes,
    stateText: PropTypes.string,
    stateIcon: PropTypes.string,
    stateAriaLabel: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    rows: PropTypes.number,
    placeholder: localizedStringOrNodePropTypes,
    ariaLabel: PropTypes.string,
    ariaDescription: PropTypes.string,
    step: PropTypes.string,
};

export default TypedInput;
